import React, { useState, useEffect } from "react";
import axios from "axios";
import { config } from "../setting";
import style from "./FitbitManager.module.css";
import Fitbit_Week_card from "./Fitbit_Week_card";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import Switch from "react-switch";

const FitbitWeeklyPrescript = ({ userFitbitInfo }) => {
  const [startData, setStartDate] = useState("");

  const [selectWeek, setSelectWeek] = useState(0);
  const [alert, setAlert] = useState();

  const options = [
    { number: 4, weeks: "4 Weeks" },
    { number: 12, weeks: "12 Weeks" },
    { number: 24, weeks: "24 Weeks" },
  ];

  const [ErrorMessage, setErrorMessage] = useState(null);

  const [weeks, setWeeks] = useState([]);

  const [prescription, setPrescription] = useState({});

  const onOptionChangeHandler = (event) => {
    setSelectWeek(event.target.value);
    console.log("User Selected Value - ", event.target.value);
  };

  const getWeeksValue = async () => {
    await axios
      .get(
        `${config.url.API_URL}/fitbit/prescribe/Weekly/patient/${userFitbitInfo.PID}/watch/${userFitbitInfo.WatchID}`
      )
      .then(async (res) => {
        if (res.data != null) {
          var data = JSON.parse(res.data.steps_array);
          console.log(res.data);
          setPrescription(res.data);
          setWeeks(data.weeks);
          setStartDate(res.data.start_date);
          setAlert(res.data.send_alert)
        }
      });
  };

  const confirmWeeks = async () => {
    console.log("Weeks ", selectWeek);
    var week = [];
    if (selectWeek != 0) {
      for (let i = 0; i < selectWeek; i++) {
        week.push(0);
      }
      setWeeks(week);
      const weeksValue = {
        weeks: week,
      };
      await axios
        .post(`${config.url.API_URL}/fitbit/prescribe/addweek`, {
          PID: userFitbitInfo.PID,
          WatchID: userFitbitInfo.WatchID,
          steps_array: weeksValue,
          start_date: new Date(),
        })
        .then((res) => {
          console.log(JSON.parse(res.data.steps_array));
          var data = JSON.parse(res.data.steps_array);
          console.log(data.weeks);
          setPrescription(res.data);
          setWeeks(data.weeks);
          setStartDate(res.data.start_date);
        })
        .catch((err) => {});
    } else {
      setErrorMessage("please select weeks");
    }
  };

  const handleChange = async () => {
    
    await axios
      .put(
        `${config.url.API_URL}/fitbit//prescribe/editWeek/alert/${prescription.id}`,
        {
          send_alert: !alert,
        }
      )
      .then((res) => {
        // console.log(res.data);
        setAlert(!alert);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getWeeksValue();
  }, [userFitbitInfo]);

  useEffect(() => {
    getWeeksValue();
  }, []);

  const editWeekValue = async (index, updatevalue) => {
    console.log(prescription.id);
    console.log(index);
    console.log(updatevalue);
    var updateWeeks = weeks;
    updateWeeks[index] = parseInt(updatevalue);
    const sendupdateWeek = {
      weeks: updateWeeks,
    };
    console.log(sendupdateWeek);
    await axios
      .put(
        `${config.url.API_URL}/fitbit/prescribe/editWeek/${prescription.id}`,
        {
          steps_array: sendupdateWeek,
        }
      )
      .then((res) => {
        console.log(JSON.parse(res.data.steps_array.weeks));
      })
      .catch((err) => {});
  };

  const updateStartDate = async () => {
    await axios
      .put(
        `${config.url.API_URL}/fitbit/prescribe/editWeek/startdate/${prescription.id}`,
        {
          start_date: startData,
        }
      )
      .then((res) => {
        console.log(JSON.parse(res.data.steps_array.weeks));
      })
      .catch((err) => {});
  };

  return (
    <div className={style.tableContainer}>
      <h3>Steps prescription</h3>
      {weeks.length > 0 ? (
        <div>
          <div style={{ margin: "20px" }}>
            <h5>start date </h5>
            <DatePicker onChange={setStartDate} value={startData} />
            <div style={{ margin: "10px" }}></div>
            <button
              type="button"
              onClick={() => updateStartDate()}
              className="btn btn-primary"
            >
              update start date
            </button>
          </div>
          <div>
            <h5>Alert</h5>
            <Switch onChange={() => handleChange()} checked={alert} />
          </div>
          <table className="table">
            <thead>
              <tr>
                <th style={{ width: "40%" }}>Weeks</th>
                <th style={{ width: "30%" }}>steps</th>
                <th style={{ width: "20%" }}></th>
                <th style={{ width: "10%" }}></th>
              </tr>
            </thead>
            <tbody>
              {weeks.map((week, index) => {
                return (
                  <Fitbit_Week_card
                    key={index}
                    week={week}
                    index={index}
                    editWeekValue={(index, e) => editWeekValue(index, e)}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <div>
          <h3>select how many week to prescribe</h3>
          <select onChange={onOptionChangeHandler}>
            <option>Please choose one option</option>
            {options.map((option, index) => {
              return (
                <option key={option.number} value={option.number}>
                  {option.weeks}
                </option>
              );
            })}
          </select>
          <div>{ErrorMessage}</div>

          <div style={{ margin: "10px" }}>
            <button
              type="button"
              onClick={() => confirmWeeks()}
              className="btn btn-outline-success"
            >
              comfirm
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FitbitWeeklyPrescript;
