import React, { useState, useEffect } from "react";
import axios from "axios";
import GridLoader from "react-spinners/GridLoader";
import style from "./NoninBLEConnection.module.css"
import { config } from "../setting";
import { useParams } from "react-router-dom";

const NoninBLEConnection = (props) => {

    
  let Device;


  var HR = 0;
  var SPO2 = 0;
  var Counter = 0;

  const HRArray = []
  const SPO2Array = []

  const [device, setDevice] = useState({});
  const [show, setShow] = useState("pair");
  const [hearRate, setHeartRate] = useState(0);
  const [spo2, setSp02] = useState(0);
  const [conter, setConter] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);

  const [connctionStart, setConnectionStart] = useState(false)
  const { id, sessionId } = useParams();

  const contentShow = () => {
    if (show == "pair") {
      return (
        <button className={style.pairbtn} onClick={requestPermission}>
          Pair
        </button>
      );
    } else if (show == "connect") {
      return (
        <button className={style.connectbtn} onClick={connecttoDevice}>
          Connect
        </button>
      );
    } else if (show == "loading") {
      return <GridLoader loading={loading} size={15} color="#36d7b7" />;
    } else if (show == "exercise") {
      return <p className={style.statusText}>Connected, please continue to start exercise</p>;
    } else if (show == "finish") {
      return <p className={style.statusText}>Finish</p>;
    }
  };

  const requestPermission = async () => {
    // alert("we will use the bluetooth port for this activity");
    Device = await navigator.bluetooth.requestDevice({
        filters: [{ namePrefix: "Nonin3150" }],
        optionalServices: [
          "46a970e0-0d5f-11e2-8b5e-0002a5d5c51b",
          "0aad7ea0-0d60-11e2-8e3c-0002a5d5c51b",
          "0000180d-0000-1000-8000-00805f9b34fb",
          "00002a37-0000-1000-8000-00805f9b34fb",
        ],
      })
      .then((device) => {
        console.log(device);
        return device;
      });

    setDevice(Device);
    setShow("connect");
   
  };

  const connecttoDevice = async () => {
    setShow("loading");

    const server = await device.gatt.connect();

    const service = await server.getPrimaryService(
      "0000180d-0000-1000-8000-00805f9b34fb"
    );

    const spo2Service = await server.getPrimaryService(
      "46a970e0-0d5f-11e2-8b5e-0002a5d5c51b"
    );

    console.log(spo2Service)

    const toggleHRCharacteristic = await service?.getCharacteristic(
      "00002a37-0000-1000-8000-00805f9b34fb"
    );

    const toggleSPO2Characteristic = await spo2Service?.getCharacteristic(
      "0aad7ea0-0d60-11e2-8e3c-0002a5d5c51b"
    );

    console.log('Start Notifications A...');
    await toggleHRCharacteristic.startNotifications();

    setConnectionStart(true)

    https://developer.chrome.com/articles/bluetooth/
    if (toggleHRCharacteristic.properties.notify) {
      toggleHRCharacteristic.addEventListener(
        "characteristicvaluechanged",
        async (event) => {
          var buffer = viewBufferAsUint8Array(event.target.value);
          //  console.log(buffer[1])
          HR = buffer[1];
          if(buffer[1] == '255'){
            setHeartRate("can not detact your heart rate");
          }else{
            setHeartRate(buffer[1].toString());
          }
          
        }
      );
      await toggleHRCharacteristic.startNotifications();
      
    }

    if (toggleSPO2Characteristic.properties.notify) {
      toggleSPO2Characteristic.addEventListener(
        "characteristicvaluechanged",
        async (event) => {
          // console.log(event.target.value)
          var buffer = viewBufferAsUint8Array(event.target.value);
          // console.log(buffer[7]);
          SPO2 = buffer[7];
          if(SPO2 == '127'){
            setSp02("can not detact your SPO2");
          }else{
            setSp02(buffer[7].toString());
          }
          
        }
      );
      await toggleSPO2Characteristic.startNotifications();
      setShow("exercise");
      const interval = setInterval(() => sendDataToDatabase(HR, SPO2), 2000);
          return () => {
            clearInterval(interval);
            
        };
    }

    

   
  };

  function viewBufferAsUint8Array(buf) {
    return new Uint8Array(buf.buffer);
  }


//   useEffect(() => {
//     if(connctionStart){
//         const interval = setInterval(() => sendDataToDatabase(), 1000);
//         return () => {
//           clearInterval(interval);
//         };
//     } 
//   }, [connctionStart]);


  const sendDataToDatabase = async(HR, SPO2) =>{

        var SPO2Value = SPO2
        var heartRate = HR
        var noninData = {
            Spo2: SPO2Value,
            Pulse_Rate: heartRate,
            Counter:Counter,
            Patient_id:id
        }
        console.log(noninData)
        Counter++
       
    // console.log(`${config.url.API_URL}/nonin/add/${id}/${SPO2Value}/${heartRate}`)
    if(SPO2Value == '127'){
        // setSp02("can not detect your heart rate")
    }else{
        await axios.get(`${config.url.API_URL}/nonin/add/${id}/${heartRate}/${SPO2Value}/${Counter}/${sessionId}`)
        .then((res) => {
            // console.log(res.data);
            
          });
    }
   
  }

    return (
        <div className={style.NoninBLEContainer}>
            {errorMessage}
            <div className={style.connectBtnContainer}>{contentShow()}</div>
            <div className={style.noninValue}>
                <h2>HR</h2>
                <h2>{hearRate}</h2>
            </div>

            <div>
                <h2>SPO2</h2>
                <h2>
                {spo2}
                </h2>
            </div>
            <p><strong>pair &#8594; find your device &#8594; connect</strong></p>
            <div className={style.imageContainer}>
                <img  src='/systemImage/spo21.jpg'/>
            </div>
        </div>
    )
}

export default NoninBLEConnection;