import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { config } from "../setting";
import { Line } from "react-chartjs-2";
import style from './FitbitManager.module.css'

const FitbitProgressDisplay = (props) => {
  const { id } = useParams();

  const [chartData, setChartData] = useState({
    labels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    datasets: [
      {
        label: "Prescription",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        backgroundColor: ["rgba(255, 206, 86, 0.6)"],
        borderWidth: 4,
      },
      {
        label: "Step",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        backgroundColor: ["rgba(75, 192, 192, 0.6)"],
        borderWidth: 4,
      },
    ],
  });

  const getFitbitProgress = async () => {
    var ProgressList = await axios.get(
      `${config.url.API_URL}/fitbit/${id}/progress_list`
    );
    
    console.log(ProgressList)

    if(ProgressList.data != null){
        const listData = ProgressList.data;

    
        setChartData({
            labels: listData.dateList,
            datasets: [
              {
                label: "Step",
                data: listData.stepList,
                backgroundColor: ["rgba(75, 192, 192, 0.6)"],
                borderWidth: 3,
                pointRadius: 6,
                borderColor: ["rgba(75, 192, 192, 0.6)"],
                pointBorderWidth: 0,
              },
              {
                label: "Prescription",
                data: listData.prescription,
                backgroundColor: ["rgba(255, 206, 86, 0.6)"],
                borderWidth: 4,
                fill: true,
                borderColor: ["rgba(255, 206, 86, 0.6)"],
              },
            ],
          });
    }
  };

  useEffect(() => {
    getFitbitProgress();
  }, []);

  return (
    <div className={style.progress_graph}>
      <Line
        data={chartData}
        options={{
          animation: { duration: 0 },
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: "Step",
              },
            },
            x: {
              title: {
                display: true,
                text: "Date",
              },
            },
          },
          elements: {
            point: {
              radius: 0,
            },
          },
        }}
      />
    </div>
  );
};

export default FitbitProgressDisplay;
