import React, { useState, useEffect } from "react";
import axios from "axios";
import { config } from "../setting";
import { alignPropType } from "react-bootstrap/esm/types";
import ContactCard from "./ContactCard";

const HeartRateLevelCard = (props) => {
  const [showEdit, setShowEdit] = useState(false);

  const [levelValue, setLevelValue] = useState(props.level.level_value);
  const [actionType, setActionType] = useState(props.level.action_type);
  const [content, setContent] = useState(props.level.content);
  const [alertContect, setAlertContact] = useState(props.level.alert_contact);

  const [updatelevelValue, setUpdateLevelValue] = useState(props.level.level_value);
  const [updateactionType, setUpdatetActionType] = useState( props.level.action_type);
  const [updatecontent, setupdateContent] = useState(props.level.content);

  const [ErrorMessage, setErrorMessage] = useState("");

  const [contactTag, setContactTage] = useState("");
  const [contactEmail, setContactEamil] = useState("");
  const [contactContent, setcontactContent] = useState("");

  const [showAddContact, setShowAddContact] = useState(false);

  // const [updateContactId, setUpdateContactId] = useState(0);


  const updateleveldata = async () => {
    if ( updateactionType == "Email/Text" && contactEmail == "" && (alertContect == undefined || alertContect.length <= 0 )) {
      setErrorMessage("Email type need to unless have one email address");
    } else {
      await axios.put(
          `${config.url.API_URL}/Heart_Rate_Alart_Value/edit/${props.level.id}`,
          {
            level_value: updatelevelValue,
            action_type: updateactionType,
            content: updatecontent,
          }
        )
        .then(async (res) => {
          // setUpdateContactId(res.data.id);
          setLevelValue(res.data.level_value);
          setActionType(res.data.action_type);
          setContent(res.data.content);
          setShowEdit(false);
          if (actionType == "Email/Text" && (alertContect.length == 0)) {
            await axios
              .post(`${config.url.API_URL}/contact/add`, {
                level_id: props.level.id,
                tag: contactTag,
                email: contactEmail,
                content: contactContent,
              })
              .then((response) => {
                //TODO: Show under level contact
                setShowAddContact(false);
                setErrorMessage("")
                setAlertContact((prevArray) => [...prevArray, response.data]);
                // window.location.reload();
              })
              .catch((err) => {
                console.log(err);
              });
          }
          setErrorMessage("")
        });
    }
  };

  const deleteContact = async (id) => {
    // console.log(alertContect.length)
    if (alertContect != undefined && alertContect.length <= 1) {
      setErrorMessage("email type need to be unless one contact");
    } else {
      await axios
        .delete(`${config.url.API_URL}/contact/delete/${id}`, {})
        .then((res) => {
          setAlertContact(alertContect.filter((item) => item.id !== id));
        });
    }
  };

  const addMoreContact = async (tag, email, content) => {
    if (email !== "") {
      await axios
        .post(`${config.url.API_URL}/contact/add`, {
          level_id: props.level.id,
          tag: tag,
          email: email,
          content: content,
        })
        .then((response) => {
          console.log(response.data);
          setShowAddContact(false);
          setAlertContact((prevArray) => [...prevArray, response.data]);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setErrorMessage("email can not be empty");
    }
  };

  return (
    <>
      <tr>
        <th scope="row">{props.index}</th>
        <td>{levelValue}</td>
        <td>{actionType}</td>
        <td style={{ textAlign: "left" }}>{content}</td>
        <td>
          <button
            type="button"
            onClick={(e) => {
              setShowEdit(!showEdit);
            }}
            className="btn btn-primary"
          >
            edit
          </button>
        </td>
        <td>
          <button
            type="button"
            onClick={(e) => {
              props.deleteLevelData(props.level.id);
            }}
            className="btn btn-danger"
          >
            delete
          </button>
        </td>
      </tr>

      {showEdit ? (
        <>
          <tr>
            <th scope="row"></th>
            <td>
              <input
                type="text"
                className="form-control"
                value={updatelevelValue}
                onChange={(e) => setUpdateLevelValue(e.target.value)}
                aria-label=""
                aria-describedby="basic-addon1"
              />
            </td>
            <td>
              <div className="input-group mb-3">
                <select
                  value={updateactionType == null ? "" : updateactionType}
                  onChange={(e) => setUpdatetActionType(e.target.value)}
                  defaultValue={props.level.action_type}
                  className="form-control"
                >
                  <option defaultValue="">choose one type</option>
                  <option value="Email/Text">Email or Text</option>
                  <option value="status check">Status Check</option>
                  <option value="sugguestion">Sugguestion</option>
                </select>
              </div>
              {/* <input type="text" className="form-control" value={updateactionType} onChange={(e) => setUpdatetActionType(e.target.value)} aria-label="" aria-describedby="basic-addon1"/> */}
            </td>
            <td>
            {(updateactionType == "Email/Text" || updateactionType == "status check" ) ? null :
              
              <input
                type="text"
                className="form-control"
                value={updatecontent}
                onChange={(e) => setupdateContent(e.target.value)}
                aria-label=""
                aria-describedby="basic-addon1"
              />
          }
            </td>
            <td>
              <button
                type="button"
                onClick={() => {
                  updateleveldata();
                }}
                className="btn btn-primary"
              >
                update
              </button>
            </td>
          </tr>
          {(updateactionType == "Email/Text") && (alertContect.length <= 0 ) ? (
            <tr>
              <td></td>
              <td>
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <div className="input-group-text">tag</div>
                  </div>
                  <input
                    type="text"
                    value={contactTag}
                    onChange={(e) => setContactTage(e.target.value)}
                    className="form-control"
                    id="inlineFormInputGroup"
                    placeholder=""
                  />
                </div>
              </td>
              <td>
                <label className="sr-only" htmlFor="inlineFormInputGroup"></label>
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <div className="input-group-text">email</div>
                  </div>
                  <input
                    type="text"
                    value={contactEmail}
                    onChange={(e) => setContactEamil(e.target.value)}
                    className="form-control"
                    id="inlineFormInputGroup"
                    placeholder=""
                  />
                </div>
              </td>
              <td>
                <label
                  className="sr-only"
                  htmlFor="inlineFormInputGroup"
                ></label>
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <div className="input-group-text">content</div>
                  </div>
                  <input
                    type="text"
                    value={contactContent}
                    onChange={(e) => setcontactContent(e.target.value)}
                    className="form-control"
                    id="inlineFormInputGroup"
                    placeholder=""
                  />
                </div>    
              </td>
            </tr>
          )  : null}
        </>
      ) : null}
      {actionType == "Email/Text" ||
      (updateactionType == "Email/Text" && (alertContect !== undefined && alertContect.length >= 1))
        ? alertContect.map((contact, i) => {
            return (
              <ContactCard
                level_id={props.level.id}
                contact={contact}
                key={i}
                deleteContact={deleteContact}
                addMoreContact={addMoreContact}
              />
            );
          })
        : null}
      {ErrorMessage == "" ? null : (
        <tr>
          <td></td>
          <td></td>
          <td>{ErrorMessage}</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      )}
    </>
  );
};

export default HeartRateLevelCard;
