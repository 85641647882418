import React, { useState } from "react";
import axios from "axios";
import { config } from "../setting";
import arrow from '../image/arrow-turn-down-right-svgrepo-com.svg'

const ContactCard = (props) => {
  const [showAddContact, setShowAddContact] = useState(false);
  const [showEditContact, setShowEditContact] = useState(false)

  const [contactTag, setContactTage] = useState("");
  const [contactEmail, setContactEamil] = useState("");
  const [contactContent, setcontactContent] = useState("");

  const [originTag, setOriginTag] = useState(props.contact.tag)
  const [originEmail, setOriginEmail] = useState(props.contact.email)
  const [originContent, setOriginContent] = useState(props.contact.content)

  const [updateContactTag, setUpdateContactTage] = useState(props.contact.tag);
  const [updateContactEmail, setUpdateContactEamil] = useState(props.contact.email);
  const [updateContactContent, setUpdateContactContent] = useState(props.contact.content);

  const [ErrorMessage, setErrorMessage] = useState("");

  
  const updateContactInfo = async (id) =>{
    if(updateContactEmail == ""){
      setErrorMessage("Email addresss can not be empty")
    }else{
      await axios.put(`${config.url.API_URL}/contact/edit/${id}`, {
          tag: updateContactTag,
          email: updateContactEmail,
          content:updateContactContent
      }).then(async(res)=>{
          console.log(res.data)
          setShowEditContact(false)
          setOriginTag(res.data.tag)
          setOriginEmail(res.data.email)
          setOriginContent(res.data.content)
          setUpdateContactTage(res.data.tag)
          setUpdateContactEamil(res.data.email)
          setUpdateContactContent(res.data.content)
      })
    } 
}
  
  return (
    <>
      <tr >
        <td style={{backgroundColor:"powderblue"}}><img style={{width:"15px", textAlign:"right", }} src={arrow}></img></td>
        <td style={{backgroundColor:"powderblue"}}>{originTag}</td>
        <td style={{backgroundColor:"powderblue"}}>{originEmail}</td>
        <td style={{ textAlign: "left",backgroundColor:"powderblue" }}>{originContent}</td>
        <td style={{backgroundColor:"powderblue"}}>
          <button className="btn btn-primary" type="button" onClick={(e) => { setShowEditContact(!showEditContact)}} >
            edit
          </button>
        </td>
        <td style={{backgroundColor:"powderblue"}}>
          <button type="button" onClick={(e) => { props.deleteContact(props.contact.id) }} className="btn btn-danger" >
            delete
          </button>
        </td>
        <td style={{backgroundColor:"powderblue"}}>
          <button type="button" onClick={(e) => { setShowAddContact(!showAddContact) }} className="btn btn-success">
            +
          </button>
        </td>
      </tr>
      {showEditContact?( <tr>
          <td></td>
          <td>
          <div className="input-group mb-2">
              <div className="input-group-prepend">
                <div className="input-group-text">tag</div>
              </div>
              <input type="text" value={updateContactTag} onChange={(e)=>setUpdateContactTage(e.target.value)} className="form-control" id="inlineFormInputGroup" placeholder=""/>
            </div>
            </td> 
          <td>
          <label className="sr-only" htmlFor="inlineFormInputGroup"></label>
            <div className="input-group mb-2">
              <div className="input-group-prepend">
                <div className="input-group-text">email</div>
              </div>
              <input type="text" value={updateContactEmail} onChange={(e)=>setUpdateContactEamil(e.target.value)} className="form-control" id="inlineFormInputGroup" placeholder=""/>
            </div>
          </td> 
          <td>
          <label className="sr-only" htmlFor="inlineFormInputGroup"></label>
            <div className="input-group mb-2">
              <div className="input-group-prepend">
                <div className="input-group-text">content</div>
              </div>
              <input type="text" value={updateContactContent} onChange={(e)=>setUpdateContactContent(e.target.value)} className="form-control" id="inlineFormInputGroup" placeholder=""/>
            </div>
          </td> 
          <td>
            <button type="button" onClick={(e) =>{updateContactInfo(props.contact.id)}} className="btn btn-success">
                update
            </button>
          </td>
      </tr>): null
      }
      {showAddContact ? (
        <tr>
          <td></td>
          <td>
            <div className="input-group mb-2">
              <div className="input-group-prepend">
                <div className="input-group-text">tag</div>
              </div>
              <input
                type="text"
                value={contactTag}
                onChange={(e) => setContactTage(e.target.value)}
                className="form-control"
                id="inlineFormInputGroup"
                placeholder=""
              />
            </div>
          </td>
          <td>
            <label className="sr-only" htmlFor="inlineFormInputGroup"></label>
            <div className="input-group mb-2">
              <div className="input-group-prepend">
                <div className="input-group-text">email</div>
              </div>
              <input
                type="text"
                value={contactEmail}
                onChange={(e) => setContactEamil(e.target.value)}
                className="form-control"
                id="inlineFormInputGroup"
                placeholder=""
              />
            </div>
          </td>
          <td>
            <label className="sr-only" htmlFor="inlineFormInputGroup"></label>
            <div className="input-group mb-2">
              <div className="input-group-prepend">
                <div className="input-group-text">content</div>
              </div>
              <input
                type="text"
                value={contactContent}
                onChange={(e) => setcontactContent(e.target.value)}
                className="form-control"
                id="inlineFormInputGroup"
                placeholder=""
              />
            </div>
          </td>
          <td>
            <button
              type="button"
              onClick={() => {
                props.addMoreContact(contactTag, contactEmail, contactContent);
                setShowAddContact(false)
              }}
              className="btn btn-success"
            >
              add
            </button>
          </td>
        </tr>
      ) : null}
       {ErrorMessage == "" ? null: 
      <tr>
      <td></td>  
      <td></td>  
      <td>{ErrorMessage}</td>
      <td></td>  
      <td></td>  
      <td></td>    
      </tr>}
    </>
  );
};

export default ContactCard;
