import React, { useState, useEffect } from "react";
import axios from "axios";
import style from "./FullPost.module.css";
import userImage from "./account.png";

import { useParams } from "react-router-dom";

const Comment = (props) => {
  const [commentAuthor, setCommentsAuthor] = useState("");
  const { id, user, userId } = useParams();
  const displayAuthor = () => {
    if (props.comment.user_group == "admin") {
      setCommentsAuthor(props.comment.hatStaff.FIRST_NAME);
    } else {
        if(user == "admin"){
            setCommentsAuthor(props.comment.hatPatient.FIRST_NAME);
        }else{
            setCommentsAuthor("Anonymous")
        }
      
    }
  };

  useEffect(() => {
    displayAuthor()
  }, [props.comment]);

  return (
    <div className={style.commentBox}>
      <div style={{ textAlign: "center" }}>
        <div>
          <img style={{ width: "50px" }} src={userImage}></img>
        </div>
        <p>{commentAuthor}</p>
      </div>
      <div>
        <div>{props.comment.content}</div>
        <div style={{ textAlign: "right" }}>
          <small>{props.comment.createdAt}</small>
        </div>
      </div>
    </div>
  );
};

export default Comment;
