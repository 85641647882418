import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import style from './FitbitManager.module.css'
import { config } from '../setting';
import fitbit_logo from './Fitbit_logo.png'
import FitbitWeeklyPrescript from "./FitbitWeeklyPrescript";


const FitbitManager = (props) => {

const [userFitbitinfo, setUserFitbitinfo] = useState({});
  const { id } = useParams();

  const [showEdit, setShowEdit] = useState(false);
  const [updateFitbit ,setUpdateFitbit] = useState(userFitbitinfo.BikeID)
  const [updateEmail, setUpdateEmail] = useState(userFitbitinfo.Nonin_mac_address)
  const [ErrorMessage, setErrorMessage] = useState("")

  const [WatchID, setWatchID] = useState("")
  const [register_email, setRegister_email] = useState("")
  const [notify, setNotify] = useState("")

  const getUserDevice = async () => {
    // console.log(config.url.API_URL)
    await axios.get(`${config.url.API_URL}/fitbit/${id}`).then(async (res) => {
    //   console.log(res.data);
      if(res.data != null){
        setUserFitbitinfo(res.data);
        setUpdateFitbit(res.data.WatchID)
        setUpdateEmail(res.data.register_email)
      } 
    
    });
  };

  const addFitbit = async () =>{
    await axios.post(`${config.url.API_URL}/fitbit/add`, {
        PID:id,
        WatchID:WatchID,
        register_email:register_email
    }).then(response =>{
        setUserFitbitinfo(response.data)
        setErrorMessage("")
        setShowEdit(false)
    }).catch(err => {
        setErrorMessage(err.response.data.error)
        console.log(err.response.data.error)
    })
  }


  const EditFitbit = async() =>{
    await axios.put(`${config.url.API_URL}/fitbit/edit/${userFitbitinfo.id}`, {
        WatchID:updateFitbit,
        register_email:updateEmail
    }).then((res)=>{
        console.log(res.data)
        setUserFitbitinfo(res.data)
        setUpdateFitbit(res.data.WatchID)
        setUpdateEmail(res.data.register_email)
        setShowEdit(false)
    });
  }

  const deleteDevice = async(fitbitID) =>{
    axios.delete(`${config.url.API_URL}/fitbit/delete/${fitbitID}`)
    .then(() => {
        setUserFitbitinfo({})
    });
  }

  useEffect(() => {
    getUserDevice();
  }, []);

  return (
    <div className={style.fitbitContainer} style={{textAlign:"center"}}>
      <h2><img style={{height:"50px", margin:"10px"}} src={fitbit_logo}/></h2>
      <div >
        { Object.keys(userFitbitinfo).length > 0 ? (
          <div className={style.tableContainer}>
            <div>
              <h3>Watch Register</h3>
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th>Watch ID</th>
                  <th>Register Email</th>
                  <th>Update At</th>
                  <th style={{width:"5%"}}></th>
                  <th style={{width:"5%"}}></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{userFitbitinfo.WatchID}</td>
                  <td>{userFitbitinfo.register_email}</td>
                  <td>{userFitbitinfo.updatedAt}</td>
                  <td><button type="button" onClick={(e) =>{ setShowEdit(!showEdit)}} className="btn btn-primary">edit</button></td>
                  <td><button type="button" onClick={(e) =>{ deleteDevice(userFitbitinfo.id)}} className="btn btn-danger">delete</button></td>
                </tr>
                {showEdit?<tr>
                  <td><input type="text" className="form-control" value={updateFitbit} onChange={(e)=>setUpdateFitbit(e.target.value)}  aria-label="" aria-describedby="basic-addon1"/></td>
                  <td><input type="text" className="form-control" value={updateEmail} onChange={(e)=>setUpdateEmail(e.target.value)}  aria-label="" aria-describedby="basic-addon1"/></td>
                  <td></td>
                  <td><button type="button" onClick={(e) =>{ EditFitbit()}} className="btn btn-primary">update</button>
                  </td>
                </tr>:null}
              </tbody>
            </table>
            
          </div>
        ) : (
          <div className={style.tableContainer}>
            <div>
              <h5>user dose not have a register Fitbit Watch</h5>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-default">Fitbit Watch ID</span>
                </div>
                <input type="text" value={WatchID} onChange={(e) =>setWatchID(e.target.value)} className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"/>
                <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-default">Register Email</span>
                </div>
                <input type="text" value={register_email} placeholder="" onChange={(e) =>setRegister_email(e.target.value)} className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"/>
              </div>
              <button type="button" onClick={() => addFitbit()} className="btn btn-outline-success">submit</button>
            </div>
          </div>
        )}
        <div>
            <p>{ErrorMessage}</p>
        </div>

        { Object.keys(userFitbitinfo).length > 0 ? (<div><FitbitWeeklyPrescript userFitbitInfo={userFitbitinfo} /></div>):null}
      </div>
    </div>
  );
}

export default FitbitManager;