import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { config } from "../setting";
import Dialog from "../layout/PostCreateDialog";
import MemberDialog from "../layout/Dialog";

import Post from "./Post";
import style from "./GroupPost.module.css";
import PostCard from "./PostCard";
import { useParams } from "react-router-dom";

// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import { ClassicEditor, Bold, Essentials, Italic, Mention, Paragraph, Undo, Indent, IndentBlock, BlockQuote, Heading, Font, Link, List, Autolink } from 'ckeditor5';
// import { SlashCommand } from 'ckeditor5-premium-features';

// import 'ckeditor5/ckeditor5.css';
// import 'ckeditor5-premium-features/ckeditor5-premium-features.css';

import { CKEditor } from "@ckeditor/ckeditor5-react";

import {
  ClassicEditor,
  AccessibilityHelp,
  Autoformat,
  AutoImage,
  AutoLink,
  Autosave,
  Bold,
  CloudServices,
  Code,
  CodeBlock,
  Essentials,
  Heading,
  ImageBlock,
  ImageCaption,
  ImageInline,
  ImageInsertViaUrl,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  ImageUpload,
  Italic,
  Link,
  LinkImage,
  List,
  ListProperties,
  Paragraph,
  SelectAll,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  TextTransformation,
  Undo,
} from "ckeditor5";

import "ckeditor5/ckeditor5.css";

const GroupPost = (props) => {
  const [group, setGroup] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [groupMember, setGroupMember] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const [gropuPosts, SetGroupPosts] = useState([]);
  const [MemberLis, setMemberList] = useState([]);
  const [selectPatient, setSelectPatient] = useState([]);
  const [displayList, setDisplayList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [memberBtn, setMemberBtn] = useState(false);
  const [patientList, setPatientList] = useState(props.patientList);

  const editorContainerRef = useRef(null);
  const editorRef = useRef(null);
  const [isLayoutReady, setIsLayoutReady] = useState(false);

  const { id, user } = useParams();
  var is_Update = false;

  const CKBOX_TOKEN_URL =
    "https://116776.cke-cs.com/token/dev/de621e0a515fb0368ddc953747e8e1107971d606e17bdefd0bb73a65f4a6?limit=10";

  const closeMemberBox = () => {
    setMemberBtn(false);
    setMemberList([]);
    setSearchInput("");
  };

  const AddMember = () => {
    if (
      selectPatient !== "" &&
      MemberLis &&
      !MemberLis.includes(selectPatient) &&
      searchInput !== ""
    ) {
      setMemberList([...MemberLis, selectPatient]);
      setSelectPatient("");
      setSearchInput("");
    }
  };

  const GetPatientList = async () => {
    await axios.get(`${config.url.API_URL}/hat_petient`).then(async (res) => {
      // console.log(res.data)
      setPatientList(res.data);
    });
  };

  const SearchPatient = () => {
    const list = patientList.filter((patient) => {
      return (
        searchInput &&
        (patient.LAST_NAME.toLowerCase().includes(searchInput) ||
          patient.FIRST_NAME.toLowerCase().includes(searchInput))
      );
    });
    setDisplayList(list);
  };

  const setSearchValue = (patient) => {
    setSearchInput(
      `${patient.Patient_ID} : ${patient.LAST_NAME}, ${patient.FIRST_NAME}`
    );
    setSelectPatient(patient);
  };

  const getGroupInfo = async () => {
    await axios
      .get(`${config.url.API_URL}/community/group_info/${props.groupID}`)
      .then(async (res) => {
        // console.log(res.data.group_members);
        setGroup(res.data);
        setGroupMember(res.data.group_members);
      });
  };

  const addNowPost = async () => {
    // console.log(id);

    await axios
      .post(`${config.url.API_URL}/community/add_post`, {
        groupID: props.groupID,
        title: title,
        content: content,
        authorID: id,
        authortype: props.userType,
      })
      .then((response) => {
        // console.log(response.data);
        SetGroupPosts([response.data, ...gropuPosts]);
        setIsOpen(false);
        setTitle("");
        setContent("");
      })
      .catch((err) => {});
  };

  const AddMemberToGroup = async () => {
    console.log(groupMember);
    const addedMemeber = await axios
      .post(`${config.url.API_URL}/community/add_member`, {
        groupID: props.groupID,
        member_list: MemberLis,
      })
      .then(async (response) => {
        // // console.log(response.data);
        for (let i = 0; i < MemberLis.length; i++) {
          console.log(MemberLis[i].FIRST_NAME);
          const hatPatient = {
            Patient_ID: MemberLis[i].Patient_ID,
            FIRST_NAME: MemberLis[i].FIRST_NAME,
            LAST_NAME: MemberLis[i].LAST_NAME,
          };
          var formatData = {
            hatPatient: hatPatient,
          };
          setGroupMember([...groupMember, formatData]);
        }
        console.log(groupMember);
        await setMemberList([]);
        await setSearchInput("");
        return response.data;
      })
      .catch((err) => {
        // setErrorMessage(err.response.data.error)
        console.log(err.response.data.error);
      });
    console.log(addedMemeber);
    setMemberBtn(false);
  };

  const getGroupPosts = async () => {
    await axios
      .get(`${config.url.API_URL}/community/get_group_post/${props.groupID}`)
      .then(async (res) => {
        // console.log(res.data);
        SetGroupPosts(res.data);
      });
  };

  useEffect(() => {
    setIsLayoutReady(true);

    return () => setIsLayoutReady(false);
  }, []);

  useEffect(() => {
    getGroupInfo();
    setIsUpdate(false);
    getGroupPosts();
    // setPatientList(props.patientList);
    GetPatientList();
    // console.log(props.patientList)
    // setDisplayList(props.patientList)
    closeMemberBox();
  }, [props.groupID]);

  useEffect(() => {
    SearchPatient();
  }, [searchInput]);

  const editorConfig = {
    toolbar: {
      items: [
        "undo",
        "redo",
        "|",
        "selectAll",
        "|",
        "heading",
        "|",
        "bold",
        "italic",
        "code",
        "|",
        "link",
        "insertTable",
        "codeBlock",
        "|",
        "bulletedList",
        "numberedList",
        "|",
        "accessibilityHelp",
      ],
      shouldNotGroupWhenFull: false,
    },
    plugins: [
      AccessibilityHelp,
      Autoformat,
      AutoImage,
      AutoLink,
      Autosave,
      Bold,
      CloudServices,
      Code,
      CodeBlock,
      Essentials,
      Heading,
      ImageBlock,
      ImageCaption,
      ImageInline,
      ImageInsertViaUrl,
      ImageResize,
      ImageStyle,
      ImageTextAlternative,
      ImageToolbar,
      ImageUpload,
      Italic,
      Link,
      LinkImage,
      List,
      ListProperties,
      Paragraph,
      SelectAll,
      Table,
      TableCaption,
      TableCellProperties,
      TableColumnResize,
      TableProperties,
      TableToolbar,
      TextTransformation,
      Undo,
    ],
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph",
        },
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
        {
          model: "heading3",
          view: "h3",
          title: "Heading 3",
          class: "ck-heading_heading3",
        },
        {
          model: "heading4",
          view: "h4",
          title: "Heading 4",
          class: "ck-heading_heading4",
        },
        {
          model: "heading5",
          view: "h5",
          title: "Heading 5",
          class: "ck-heading_heading5",
        },
        {
          model: "heading6",
          view: "h6",
          title: "Heading 6",
          class: "ck-heading_heading6",
        },
      ],
    },
    image: {
      toolbar: [
        "toggleImageCaption",
        "imageTextAlternative",
        "|",
        "imageStyle:inline",
        "imageStyle:wrapText",
        "imageStyle:breakText",
        "|",
        "resizeImage",
      ],
    },
    // initialData:
    // 	'<h2>Congratulations on setting up CKEditor 5! 🎉</h2>\n<p>\n    You\'ve successfully created a CKEditor 5 project. This powerful text editor will enhance your application, enabling rich text editing\n    capabilities that are customizable and easy to use.\n</p>\n<h3>What\'s next?</h3>\n<ol>\n    <li>\n        <strong>Integrate into your app</strong>: time to bring the editing into your application. Take the code you created and add to your\n        application.\n    </li>\n    <li>\n        <strong>Explore features:</strong> Experiment with different plugins and toolbar options to discover what works best for your needs.\n    </li>\n    <li>\n        <strong>Customize your editor:</strong> Tailor the editor\'s configuration to match your application\'s style and requirements. Or even\n        write your plugin!\n    </li>\n</ol>\n<p>\n    Keep experimenting, and don\'t hesitate to push the boundaries of what you can achieve with CKEditor 5. Your feedback is invaluable to us\n    as we strive to improve and evolve. Happy editing!\n</p>\n<h3>Helpful resources</h3>\n<ul>\n    <li>📝 <a href="https://orders.ckeditor.com/trial/premium-features">Trial sign up</a>,</li>\n    <li>📕 <a href="https://ckeditor.com/docs/ckeditor5/latest/installation/index.html">Documentation</a>,</li>\n    <li>⭐️ <a href="https://github.com/ckeditor/ckeditor5">GitHub</a> (star us if you can!),</li>\n    <li>🏠 <a href="https://ckeditor.com">CKEditor Homepage</a>,</li>\n    <li>🧑‍💻 <a href="https://ckeditor.com/ckeditor-5/demo/">CKEditor 5 Demos</a>,</li>\n</ul>\n<h3>Need help?</h3>\n<p>\n    See this text, but the editor is not starting up? Check the browser\'s console for clues and guidance. It may be related to an incorrect\n    license key if you use premium features or another feature-related requirement. If you cannot make it work, file a GitHub issue, and we\n    will help as soon as possible!\n</p>\n',
    link: {
      addTargetToExternalLinks: true,
      defaultProtocol: "https://",
      decorators: {
        toggleDownloadable: {
          mode: "manual",
          label: "Downloadable",
          attributes: {
            download: "file",
          },
        },
      },
    },
    list: {
      properties: {
        styles: true,
        startIndex: true,
        reversed: true,
      },
    },
    placeholder: "Type or paste your content here!",
    table: {
      contentToolbar: [
        "tableColumn",
        "tableRow",
        "mergeTableCells",
        "tableProperties",
        "tableCellProperties",
      ],
    },
  };

  return (
    <div>
      <h2>{group.groupName}</h2>

      <div>
        {user == "admin" ? (
          <div className={style.memberBox}>
            <h5>Member:</h5>
            <div>
              {groupMember.length !== 0 ? (
                <div>
                  {groupMember.map((member) => {
                    return (
                      <span key={member.id}>
                        {member.hatPatient.FIRST_NAME}{" "}
                        {member.hatPatient.LAST_NAME},{" "}
                      </span>
                    );
                  })}
                  <button
                    onClick={(e) => setMemberBtn(true)}
                    className={style.addBtn}
                  >
                    +
                  </button>
                  <MemberDialog
                    isOpen={memberBtn}
                    onClose={(e) => setMemberBtn(false)}
                  >
                    {/* <div>
                      {MemberLis.length !== 0 ? (
                        <div>
                          {MemberLis.map((patient) => {
                            return (
                              <p>
                                {patient.LAST_NAME}, {patient.FIRST_NAME}
                              </p>
                            );
                          })}
                        </div>
                      ) : null}
                      <div class="input-group mb-3">
                        <span
                          class="input-group-text"
                          id="inputGroup-sizing-default"
                        >
                          Member
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          aria-label="Sizing example input"
                          placeholder="Search by name"
                          aria-describedby="inputGroup-sizing-default"
                          value={searchInput}
                          onChange={(e) => {
                            setSearchInput(e.target.value);
                          }}
                        />
                        <button
                          class="btn btn-outline-secondary"
                          type="button"
                          id="button-addon2"
                          onClick={() => AddMember()}
                        >
                          +
                        </button>
                      </div>
                      <div className={style.resultList}>
                        {displayList.length !== 0
                          ? displayList.map((patient) => {
                              return (
                                <div
                                  key={patient.Patient_ID}
                                  onClick={() => setSearchValue(patient)}
                                >
                                  {patient.LAST_NAME}, {patient.FIRST_NAME}
                                </div>
                              );
                            })
                          : null}
                      </div>
                      <button onClick={() => AddMemberToGroup()}   onClose={(e) => setMemberBtn(false)}>Create</button>
                      <button onClick={(e) => setMemberBtn(false)}> Clost </button>
                    </div> */}
                    <div>
                      {MemberLis.length !== 0 ? (
                        <div>
                          {MemberLis.map((patient) => {
                            return (
                              <p key={patient.id}>
                                {patient.LAST_NAME}, {patient.FIRST_NAME}
                              </p>
                            );
                          })}
                        </div>
                      ) : null}
                      <div className="input-group mb-3">
                        <span
                          className="input-group-text"
                          id="inputGroup-sizing-default"
                        >
                          Member
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          aria-label="Sizing example input"
                          placeholder="Search by name"
                          aria-describedby="inputGroup-sizing-default"
                          value={searchInput}
                          onChange={(e) => {
                            setSearchInput(e.target.value);
                          }}
                        />
                        <button
                          className="btn btn-outline-secondary"
                          type="button"
                          id="button-addon2"
                          onClick={() => AddMember()}
                        >
                          +
                        </button>
                      </div>
                      <div className={style.resultList}>
                        {displayList.length !== 0
                          ? displayList.map((patient) => {
                              return (
                                <div
                                  key={patient.Patient_ID}
                                  onClick={() => setSearchValue(patient)}
                                >
                                  {patient.LAST_NAME}, {patient.FIRST_NAME}
                                </div>
                              );
                            })
                          : null}
                      </div>
                      <button
                        className={style.PostSubmitBtn}
                        onClick={() => AddMemberToGroup()}
                      >
                        Create
                      </button>
                      <button
                        className={style.PostSubmitBtn}
                        onClick={(e) => closeMemberBox()}
                      >
                        Close
                      </button>
                    </div>
                  </MemberDialog>
                </div>
              ) : (
                <div>
                  <div>
                    <button
                      onClick={(e) => setMemberBtn(true)}
                      className={style.addBtn}
                    >
                      +
                    </button>
                    <MemberDialog
                      isOpen={memberBtn}
                      onClose={(e) => setMemberBtn(false)}
                    >
                      <div>
                        {MemberLis.length !== 0 ? (
                          <div>
                            {MemberLis.map((patient) => {
                              return (
                                <p key={patient.id}>
                                  {patient.LAST_NAME}, {patient.FIRST_NAME}
                                </p>
                              );
                            })}
                          </div>
                        ) : null}
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text"
                            id="inputGroup-sizing-default"
                          >
                            Member
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            aria-label="Sizing example input"
                            placeholder="Search by name"
                            aria-describedby="inputGroup-sizing-default"
                            value={searchInput}
                            onChange={(e) => {
                              setSearchInput(e.target.value);
                            }}
                          />
                          <button
                            className="btn btn-outline-secondary"
                            type="button"
                            id="button-addon2"
                            onClick={() => AddMember()}
                          >
                            +
                          </button>
                        </div>
                        <div className={style.resultList}>
                          {displayList.length !== 0
                            ? displayList.map((patient) => {
                                return (
                                  <div
                                    key={patient.Patient_ID}
                                    onClick={() => setSearchValue(patient)}
                                  >
                                    {patient.LAST_NAME}, {patient.FIRST_NAME}
                                  </div>
                                );
                              })
                            : null}
                        </div>
                        <button
                          className={style.PostSubmitBtn}
                          onClick={() => AddMemberToGroup()}
                        >
                          Create
                        </button>
                        <button
                          className={style.PostSubmitBtn}
                          onClick={(e) => closeMemberBox()}
                        >
                          Close
                        </button>
                      </div>
                    </MemberDialog>
                  </div>
                  This group dose not have a member please add member
                </div>
              )}
            </div>
          </div>
        ) : null}
      </div>

      {/* <h5>Post:</h5> */}
      <div>
        <button
          className={style.CreateNowPostBtn}
          onClick={(e) => setIsOpen(!isOpen)}
        >
          {" "}
          Create New Post{" "}
        </button>
        <Dialog isOpen={isOpen} onClose={(e) => setIsOpen(!isOpen)}>
          <div>
            <div className="input-group mb-3">
              <span className="input-group-text" id="inputGroup-sizing-default">
                Title
              </span>
              <input
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                type="text"
                className="form-control"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-default"
              />
            </div>
            <div className="main-container">
              <div
                className="editor-container editor-container_classic-editor"
                ref={editorContainerRef}
              >
                <div className="ck-editor__editable_inline editor-container__editor">
                  <div ref={editorRef}>
                    {isLayoutReady && (
                      <CKEditor
                        editor={ClassicEditor}
                        config={editorConfig}
                        data={content}
                        onChange={(s, editor) => {
                          const data = editor.getData();
                          setContent(data);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "10px" }}>
            <button
              className={style.PostSubmitBtn}
              onClick={() => addNowPost()}
            >
              Submit post
            </button>
            <button
              className={style.PostSubmitBtn}
              onClick={(e) => setIsOpen(!isOpen)}
            >
              Clost
            </button>
          </div>
        </Dialog>
      </div>
      <div>
        {gropuPosts.length > 0 ? (
          <div>
            {gropuPosts.map((post) => {
              return (
                <Post
                  key={post.id}
                  post={post}
                  userInfo={props.userInfo}
                  userType={props.userType}
                  userId={id}
                />
              );
            })}
          </div>
        ) : (
          <div>Do not have post, please add new post</div>
        )}
      </div>
    </div>
  );
};

function configUpdateAlert(config) {
  if (configUpdateAlert.configUpdateAlertShown) {
    return;
  }

  const isModifiedByUser = (currentValue, forbiddenValue) => {
    if (currentValue === forbiddenValue) {
      return false;
    }

    if (currentValue === undefined) {
      return false;
    }

    return true;
  };

  const valuesToUpdate = [];

  configUpdateAlert.configUpdateAlertShown = true;

  if (
    !isModifiedByUser(
      config.ckbox?.tokenUrl,
      "https://116776.cke-cs.com/token/dev/de621e0a515fb0368ddc953747e8e1107971d606e17bdefd0bb73a65f4a6?limit=10"
    )
  ) {
    valuesToUpdate.push("CKBOX_TOKEN_URL");
  }

  if (valuesToUpdate.length) {
    window.alert(
      [
        "Please update the following values in your editor config",
        "in order to receive full access to the Premium Features:",
        "",
        ...valuesToUpdate.map((value) => ` - ${value}`),
      ].join("\n")
    );
  }
}

export default GroupPost;
