import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import style from './DeviceRegister.module.css'
import { config } from '../setting';

const DeviceRegister = (props) => {
  const [userBikeinfo, setUserBikeinfo] = useState({});
  const { id } = useParams();

  const [showEdit, setShowEdit] = useState(false);
  const [updateBike ,setUpdateBike] = useState(userBikeinfo.BikeID)
  const [updateNonin, setUpdateNonin] = useState(userBikeinfo.Nonin_mac_address)
  const [ErrorMessage, setErrorMessage] = useState("")

  const [bikeName, setBikeName] = useState("")
  const [Nonin_mac_address, setNonin_mac_address] = useState("")

  const getUserDevice = async () => {
    // console.log(config.url.API_URL)
    await axios.get(`${config.url.API_URL}/BikeID/${id}`).then(async (res) => {
    //   console.log(res.data);
      if(res.data != null){
        setUserBikeinfo(res.data);
        setUpdateBike(res.data.BikeID)
        setUpdateNonin(res.data.Nonin_mac_address)
      } 
    
    });
  };

  const addBike = async () =>{
    await axios.post(`${config.url.API_URL}/BikeID/add`, {
        PID:id,
        BikeID:bikeName,
        Nonin_mac_address:Nonin_mac_address
    }).then(response =>{
        setUserBikeinfo(response.data)
        setErrorMessage("")
        setShowEdit(false)
    }).catch(err => {
        setErrorMessage(err.response.data.error)
        console.log(err.response.data.error)
    })
  }


  const EditBike = async() =>{
    await axios.put(`${config.url.API_URL}/BikeID/edit/${userBikeinfo.id}`, {
        BikeID:updateBike,
        Nonin_mac_address:updateNonin
    }).then((res)=>{
        console.log(res.data)
        setUserBikeinfo(res.data)
        setUpdateBike(res.data.BikeID)
        setUpdateNonin(res.data.updateNonin)
        setShowEdit(false)
    });
  }

  const deleteDevice = async() =>{
    axios.delete(`${config.url.API_URL}/BikeID/delete/${userBikeinfo.id}`)
    .then(() => {
        setUserBikeinfo({})
    });
  }

  useEffect(() => {
    getUserDevice();
  }, []);

  return (
    <div style={{textAlign:"center"}}>
      <h2>Register Device</h2>
      <div>
        { Object.keys(userBikeinfo).length > 0 ? (
          <div className={style.tableContainer}>
            <div>
              <h3>Bike Register for this patient</h3>
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th>Bike Name</th>
                  <th>Nonin Machine Number</th>
                  <th>Update At</th>
                  <th style={{width:"5%"}}></th>
                  <th style={{width:"5%"}}></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{userBikeinfo.BikeID}</td>
                  <td>{userBikeinfo.Nonin_mac_address}</td>
                  <td>{userBikeinfo.updatedAt}</td>
                  <td><button type="button" onClick={(e) =>{ setShowEdit(!showEdit)}} className="btn btn-primary">edit</button></td>
                  <td><button type="button" onClick={(e) =>{ deleteDevice(userBikeinfo.id)}} className="btn btn-danger">delete</button></td>
                </tr>
                {showEdit?<tr>
                  <td><input type="text" className="form-control" value={updateBike} onChange={(e)=>setUpdateBike(e.target.value)}  aria-label="" aria-describedby="basic-addon1"/></td>
                  <td><input type="text" className="form-control" value={updateNonin} onChange={(e)=>setUpdateNonin(e.target.value)}  aria-label="" aria-describedby="basic-addon1"/></td>
                  <td></td>
                  <td><button type="button" onClick={(e) =>{ EditBike()}} className="btn btn-primary">update</button>
                  </td>
                </tr>:null}
              </tbody>
            </table>
            
          </div>
        ) : (
          <div className={style.tableContainer}>
            <div>
              <h5>user dose not have a register bike</h5>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-default">Bike Name</span>
                </div>
                <input type="text" value={bikeName} onChange={(e) =>setBikeName(e.target.value)} className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"/>
                <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-default">Nonin Mac Address</span>
                </div>
                <input type="text" value={Nonin_mac_address} placeholder="Nonin format: Nonin3150_XXXXXXXXX" onChange={(e) =>setNonin_mac_address(e.target.value)} className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"/>
              </div>
              <button type="button" onClick={() => addBike()} className="btn btn-outline-success">submit</button>
            </div>
          </div>
        )}
        <div>
            <p>{ErrorMessage}</p>
        </div>
      </div>
    </div>
  );
};

export default DeviceRegister;
