import React, { useState, useEffect } from "react";
import axios from "axios";
import { redirect } from "react-router-dom";
import style from './FitbitManager.module.css'
import { config } from "../setting";

const FitbitDashboard = (props) => {

    const ClientID = "23PHQJ"
    const ClientSecret = "e09741fdd60648f7e1e9b1f8e884995b"
    const RedirectURL = "https://tele-manager.org/fitbit/dashboard"

    const [TokenInfo, SetTokenInfo] = useState({})
    const [encodedId, SetIncodeID] = useState("")

    const getToken = () => {
        var url = window.location.href;
        
        var authCode = url.split("#")[0].split("=")[1];
 
        var client_id = ClientID
        var client_Secret = ClientSecret
        var ToBase64Val = btoa(client_id + ":" + client_Secret)
        // console.log("login token :"  +  ToBase64Val)


        // 3) build the header
        var authorization = "Basic " + ToBase64Val
        // console.log(authorization)

        axios
            .post('https://api.fitbit.com/oauth2/token', null, {
                headers: {
                    "Authorization": authorization,
                    "Content-Type": "application/x-www-form-urlencoded"
                }, params: {
                    code: authCode,
                    grant_type: "authorization_code",
                    redirect_uri: RedirectURL
                }
            })
            .then(response => {
                console.log(response.data)
                GetUserWatchInfo(response.data)
            })
            .catch(err => {
                console.log(err)
            })
    }


    const GetUserWatchInfo = (tokenInfo) => {

        var requestHeader = "Bearer " + tokenInfo.access_token

        axios.get('https://api.fitbit.com/1/user/-/profile.json', {
            headers: {
                "Authorization": requestHeader
            }})
            .then(response => {
                console.log(response.data)
                SetTokenInfo(response.data)
                const userdata = response.data.user
                // setUserProfile({ 
                //     fullName: userdata.fullName,
                //     encodedId: userdata.encodedId,
                //     averageDailySteps: userdata.averageDailySteps,
                //     gender: userdata.gender,
                // })
                storeUserData(userdata, tokenInfo)
                SetIncodeID(userdata.encodedId)
            })
            .catch(error => {
                console.log(error)
            })

    }

    const storeUserData = async(userdata, tokenInfo) => {
        var access_token = tokenInfo.access_token
        var refresh_token = tokenInfo.refresh_token
         await axios.post(`${config.url.API_URL}/fitbit/fitbitUser/add`, {
            encodeID: userdata.encodedId, 
            access_token: access_token, 
            refresh_token: refresh_token,
            firstName: userdata.firstName, 
            lastName: userdata.lastName,
            fullName : userdata.fullName, 
            gender: userdata.gender, 
            age: userdata.age, 
            dateOfBirth:userdata.dateOfBirth, 
            averageDailySteps:userdata.averageDailySteps,
            memberSince:userdata.memberSince, 
            timezone:userdata.timezone
        })
        .then(async (response) => {
            console.log(response)

        })

    }

    useEffect(() => {
        getToken();
    }, []);



    return (
        <div className={style.fitbitdashboard}>
           <h4>you have successfully login and allow sharing your fitbit data</h4>
           <h4>Please write down your user ID below and share with our HAT clinician team </h4>
           <div className={style.encodeID}>
                <h1>{encodedId}</h1>
           </div>
           <h4>Thank you</h4>
           
        </div>
    )
}

export default FitbitDashboard;