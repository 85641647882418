import React, { useState, useEffect } from "react";
import axios from "axios";



const ChatBox = (props) => {

    

    return (
        <div>
            ChatBox
            {props.Patient_ID}
        </div>
    )
}

export default ChatBox;