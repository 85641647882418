import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Webcam from "react-webcam";
import { Camera } from "@mediapipe/camera_utils";
import {
  FACEMESH_TESSELATION,
  HAND_CONNECTIONS,
  Holistic,
  Results,
  POSE_CONNECTIONS,
} from "@mediapipe/holistic";
import { drawConnectors, drawLandmarks } from "@mediapipe/drawing_utils";
import style from "./PostDection.module.css";
import finishUrl from "./success.mp3";
import { useParams } from "react-router-dom";
import { config } from "./../setting";

function MediapipePost() {
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  var defaultSetCountDown = 60;

  const { id } = useParams();

  const [errorMessage, setError] = useState("");
  const [action, setAction] = useState("");
  const [count, setCount] = useState(0);
  const [sideDetect, setSiteDetect] = useState("");
  const [ExeStart, setExeStart] = useState(false);

  const [Timecounter, setTimeCounter] = useState(defaultSetCountDown);
  const [intervalId, setIntervalId] = useState(null);
  const [process, setProcess] = useState("Start Exercise");
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const [playing, toggle] = useAudio(finishUrl);
  const [array, setArray] = useState([]);

  const [exercising , setExercising] = useState(false);

  var sitCount = 0;
  var isStanding = false;

  var isSide = "";
  var timeCount = 0;
  var exerciseArray = [];

  const onResults = (results) => {
    if (!webcamRef.current?.video || !canvasRef.current) return;
    const videoWidth = webcamRef.current.video.videoWidth;
    const videoHeight = webcamRef.current.video.videoHeight;
    canvasRef.current.width = videoWidth;
    canvasRef.current.height = videoHeight;

    const canvasElement = canvasRef.current;
    const canvasCtx = canvasElement.getContext("2d");

    if (canvasCtx == null) throw new Error("Could not get context");
    canvasCtx.save();
    canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);

    // Only overwrite existing pixels.
    canvasCtx.globalCompositeOperation = "source-in";
    canvasCtx.fillRect(0, 0, canvasElement.width, canvasElement.height);

    // Only overwrite missing pixels.
    canvasCtx.globalCompositeOperation = "destination-atop";
    canvasCtx.drawImage(
      results.image,
      0,
      0,
      canvasElement.width,
      canvasElement.height
    );

    canvasCtx.globalCompositeOperation = "source-over";
    drawConnectors(canvasCtx, results.poseLandmarks, POSE_CONNECTIONS, {
      color: "#00FF00",
      lineWidth: 1,
    });
    drawLandmarks(canvasCtx, results.poseLandmarks, {
      color: "#FF0000",
      lineWidth: 1,
      radius: 1,
    });

    var landmark = [];
    if (results.poseLandmarks) {
      landmark = results.poseLandmarks;
    }

    if (landmark.length > 0) {
      var right_shoulder = results.poseLandmarks[11];
      var right_hip = results.poseLandmarks[23];
      var right_knee = results.poseLandmarks[25];

      var left_shoulder = results.poseLandmarks[12];
      var left_hip = results.poseLandmarks[24];
      var let_knee = results.poseLandmarks[26];

      var rignt_angle = calculateAngleLeft(
        right_shoulder,
        right_hip,
        right_knee
      );
      var left_angle = calculateAngle(left_shoulder, left_hip, let_knee);
      //   var exactLeft = exactAngle(left_shoulder, left_hip, let_knee)
      var angle = left_angle;

      var left_or_right = leftSideOrRightSide(right_hip, left_hip);
      //   console.log(left_or_right)
      if (left_or_right == "right") {
        angle = left_angle;
      } else if (left_or_right == "left") {
        angle = rignt_angle;
      } else if (left_or_right == "middle") {
        angle = 0;
        setAction("No detect");
      }

      if (angle > 160) {
        setAction("Stand");
        isStanding = true;
      } else if (angle < 120 && angle > 10) {
        // console.log(angle)
        if (isStanding) {
          sitCount++;
          const exerciseValue = {
            count: sitCount,
            timeStamp: Date.now(),
          };
          exerciseArray.push(exerciseValue);
          //setArray(exerciseArray);
          setArray(exerciseArray);
          // setExerciseArray([...exerciseArray, exerciseValue])
          console.log(exerciseArray)
          setCount(sitCount);
          isStanding = false;
        }
        // setCount(actionCount)
        setAction("Sit");
      } else if (angle == 0) {
        isStanding = false;
        setAction("No detect");
      }
    }

    //   console.log(results)
    // drawConnectors(canvasCtx, results.faceLandmarks, FACEMESH_TESSELATION,
    //   {color: '#C0C0C070', lineWidth: 1});
    // drawConnectors(canvasCtx, results.leftHandLandmarks, HAND_CONNECTIONS,
    //   {color: '#CC0000', lineWidth: 5});
    // drawLandmarks(canvasCtx, results.leftHandLandmarks,
    //   {color: '#00FF00', lineWidth: 2});
    // drawConnectors(canvasCtx, results.rightHandLandmarks, HAND_CONNECTIONS,
    //   {color: '#00CC00', lineWidth: 5});
    // drawLandmarks(canvasCtx, results.rightHandLandmarks,
    //   {color: '#FF0000', lineWidth: 2});
    canvasCtx.restore();
  };

  const leftSideOrRightSide = (right, left) => {
    if (right.visibility > 0.6 && left.visibility > 0.6) {
      //   console.log(`right : ${right.z}`);
      //   console.log(`left : ${left.z}`);
      if (right.z > left.z && right.z > 0.08) {
        isSide = "right";
        setSiteDetect(isSide);
        return "right";
      } else if (right.z < left.z && left.z > 0.08) {
        isSide = "left";
        setSiteDetect(isSide);
        return "left";
      } else {
        isSide = "middle";
        setSiteDetect(isSide);
        return "middle";
      }
    }
  };

  const calculateAngle = (a, b, c) => {
    if (a.visibility > 0.6 && b.visibility > 0.6 && c.visibility > 0.6) {
      var radians =
        Math.atan2(c.y - b.y, c.x - b.x) - Math.atan2(a.y - b.y, a.x - b.x);
      var angle = (radians * 180) / Math.PI;
      if (angle > 180.0) {
        angle = 360 - 180;
      }
      //   setError("");
      return angle;
    } else {
      //   setError("we need to see detect your knee, hip and shoulder to be able to calculate");
      return 0;
    }
    // console.log(a,b,c)
  };

  const calculateAngleLeft = (a, b, c) => {
    if (a.visibility > 0.6 && b.visibility > 0.6 && c.visibility > 0.6) {
      var radians =
        Math.atan2(c.y - b.y, c.x - b.x) - Math.atan2(a.y - b.y, a.x - b.x);
      var angle = (radians * 180) / Math.PI;
      if (angle > 180.0) {
        angle = angle - 180;
      }
      //   setError("");
      return angle;
    } else {
      //   setError("we need to see detect your knee, hip and shoulder to be able to calculate");
      return 0;
    }
    // console.log(a,b,c)
  };

  const StartExercise = async () => {
    timeCount = defaultSetCountDown;
    setCount(0);
    setButtonDisabled(true);
    setProcess("Detecting...");
    // exerciseArray = []
    const interval = setInterval(() => {
      if (timeCount > 0) {
        timeCount--;
        setTimeCounter(timeCount);
      } else {
        toggle();
        // console.log(array);
        // console.log(exerciseArray);
        setButtonDisabled(false);
        setProcess("Restart Exercise");
        setExercising(true)
        // sendDatatoDatabase()
        return clearInterval(interval);
      }
    }, 1000);

    console.log(interval)
    
  };

  const sendDatatoDatabase = async() =>{
    console.log("Send data")
    const dataSend = {
      pid: parseInt(id),
      detect_value: count,
      action:"STS",
      activity_meta: array
    }
    console.log(dataSend)
    await axios
        .post(`${config.url.API_URL}/detection/add`, dataSend)
        .then(function (response) {
          console.log(response)
        })
        .catch(function (error) {
          // console.log(error);
        });
  }

  useEffect(() => { 
    if(exercising == true){
      console.log("Send Data")
      sendDatatoDatabase()
      setExercising(false)
    }
  }, [exercising]);

  useEffect(() => {
    const holistic = new Holistic({
      locateFile: (file) => {
        return `https://cdn.jsdelivr.net/npm/@mediapipe/holistic/${file}`;
      },
    });
    holistic.setOptions({
      selfieMode: true,
      modelComplexity: 1,
      smoothLandmarks: true,
      enableSegmentation: true,
      smoothSegmentation: true,
      refineFaceLandmarks: true,
      minDetectionConfidence: 0.5,
      minTrackingConfidence: 0.5,
    });

    holistic.onResults(onResults);

    if (
      typeof webcamRef.current !== "undefined" &&
      webcamRef.current !== null
    ) {
      if (!webcamRef.current?.video) return;
      const camera = new Camera(webcamRef.current.video, {
        onFrame: async () => {
          if (!webcamRef.current?.video) return;
          await holistic.send({ image: webcamRef.current.video });
        },
        width: 640,
        height: 480,
      });
      camera.start();
    }
  }, []);

  return (
    <div className={style.PDContainer}>
      <div className={style.cameraSpace}>
        <Webcam
          ref={webcamRef}
          style={{
            position: "absolute",
            marginLeft: "auto",
            marginRight: "auto",
            left: 0,
            right: 0,
            textAlign: "center",
            zIndex: 9,
            width: 840,
            height: 560,
          }}
        />
        <canvas
          ref={canvasRef}
          style={{
            position: "absolute",
            marginLeft: "auto",
            marginRight: "auto",
            left: 0,
            right: 0,
            textAlign: "center",
            zIndex: 9,
            width: 840,
            height: 560,
          }}
        />
      </div>

      <div className={style.notifiyActionTextBox}>
        <div>
          <h2>Timer : {Timecounter} second</h2>
          <h2>Direction : {sideDetect}</h2>
          <h2>Action : {action}</h2>
          <h2>Count : {count}</h2>
        </div>
        <div className={style.exeBtn}>
          <div>{}</div>
          <button
            disabled={isButtonDisabled}
            className={style.gridItem}
            onClick={() => {
              StartExercise();
            }}
          >
            {process}
          </button>
          <div>{}</div>
        </div>

        {/* <h1>{errorMessage}</h1>  */}
        {/* <div>
          <div>
            <h1>Detect Action:</h1>
          </div>
          <div>
            <h1>count:</h1>
          </div>
        </div> */}
      </div>
    </div>
  );
}

const useAudio = (url) => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing]);

  useEffect(() => {
    audio.addEventListener("ended", () => setPlaying(false));
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false));
    };
  }, []);

  return [playing, toggle];
};

export default MediapipePost;
