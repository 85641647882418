import React, { useState, useEffect } from "react";
import axios from "axios";
import style from "./HeartRateLevel.module.css";

const HightHeartRateDuration = (props) => {

    const utcDate = new Date(props.repeat.start_time)
    utcDate.toLocaleString('en-US')
    utcDate.toLocaleString('en-US', { timeZone: 'America/Denver' })
    // console.log(utcDate.getHours())

  return (
    <tr>
      <td className={style.durationtd}>{props.repeat.target_hr}</td>
      <td className={style.durationtd}>{utcDate.getHours()}:{utcDate.getMinutes()}:{utcDate.getSeconds()}</td>
      <td className={style.durationtd}>{props.repeat.duration} second</td>
    </tr>
  );
};

export default HightHeartRateDuration;
