import React, { useState, useEffect } from "react";
import axios from "axios";
import parse from "html-react-parser";
import style from "./GroupPost.module.css";
import { config } from "../setting";
import { Link } from "react-router-dom";

const Post = (props) => {
  const [comments, setComments] = useState("");
  const [commentList, setCommentList] = useState([])
  const [postContent , setPostContent] = useState('')

  const sendCommet = async() =>{
    await axios
    .post(`${config.url.API_URL}/community/add_comment`, {
        postId: props.post.id, 
        user_group:'admin', 
        userID: 1, 
        content:comments
    })
    .then((response) => {
      console.log(response.data);
      setCommentList([ response.data, ...commentList ])
      setComments('')
    })
    .catch((err) => {
      
    });
  }

  const DisplayShort = () =>{
    const postContent = props.post.content
    const Paragraph = postContent.split('</p>');
    if (Paragraph.length > 1){
        setPostContent(Paragraph[0]+Paragraph[1])
    }else{
        setPostContent(Paragraph[0])
    }
   
  }

  
  useEffect(() => {
    DisplayShort();
}, [props.post.id]);

  return (
    <div className={style.PostCard}>
      <div>
        <h3>{props.post.title}</h3>
      </div>
      {/* <div>{parse(`${props.post.content}`)}</div> */}
      <div>{parse((`${postContent}`))}</div>
      <div>
        <Link target="_blank" to={`/full_post/${props.post.id}/${props.userType}/${props.userId}`}>Read more...</Link>
      </div>
      <small>{props.post.createdAt}</small>
      <div>
        <hr/>
      </div>
      {/* <div>
        <div class="form-group">
          <label for="exampleFormControlTextarea1">
            {" "}
            <b>comments</b>
          </label>
          <textarea
            className="form-control"
            id="exampleFormControlTextarea1"
            rows="3"
            value={comments}
            onChange={(e) =>{setComments(e.target.value)}}
          ></textarea>
          <button onClick={() => sendCommet()} className={style.SendCommentBtn}>
            send comment
          </button>
        </div>
      </div> */}
    </div>
  );
};

export default Post;
