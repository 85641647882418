import React, { useState, useEffect } from "react";
import axios from "axios";
import style from "./HeartRateLevel.module.css";
import drop_down_right from "../image/drop-down-right.svg";
import drop_down from "../image/dropdown.svg";

const AlertRecord = (props) => {
  var [showStatusCheck, setShowStatusCheck] = useState(false);

  const utcDate = new Date(props.record.createdAt)
  utcDate.toLocaleString('en-US')
  utcDate.toLocaleString('en-US', { timeZone: 'America/Denver' })

  return (
    <>
      <tr>
        <td className={style.durationtd}>
          {props.record.action_type == "status check" ? (
            <button
              className={style.recordButoon}
              onClick={() => {
                setShowStatusCheck(!showStatusCheck);
              }}
            >
              {showStatusCheck ? (
                <img style={{ width: "40px" }} src={drop_down} />
              ) : (
                <img style={{ width: "20px" }} src={drop_down_right} />
              )}
            </button>
          ) : null}
        </td>
        <td className={style.durationtd}>{props.record.heart_rate}</td>
        <td className={style.durationtd}>{props.record.action_type}</td>
        <td className={style.durationtd}>{utcDate.getHours()}:{utcDate.getMinutes()}:{utcDate.getSeconds()}</td>
      </tr>
      {showStatusCheck ? (
        <>
          <tr>
            <td></td>
            <td className={style.recordTitleRow}>action</td>
            <td className={style.recordTitleRow}>stress level</td>
            <td className={style.recordTitleRow}>other info</td>
          </tr>
          <tr>
            <td></td>
            <td className={style.durationtd}>
              {props.record.user_status_feedback !== null
                ? props.record.user_status_feedback.status
                : "No record"}
            </td>
            <td className={style.durationtd}>
              {props.record.user_status_feedback !== null ? (
                props.record.user_status_feedback.stress_level
              ) : (
                <p> </p>
              )}
            </td>
            <td className={style.durationtd}>
              {props.record.user_status_feedback !== null ? (
                props.record.user_status_feedback.more_info
              ) : (
                <p> </p>
              )}
            </td>
          </tr>
        </>
      ) : null}
    </>
  );
};

export default AlertRecord;
