import React, { useState, useEffect } from "react";
const Buffer = require("buffer/").Buffer;


function BluetoothConnection() {

  const noninServicseUUID = "0000180d-0000-1000-8000-00805f9b34fb";
  const noninHartRatecharacteristicsUUID ="00002a37-0000-1000-8000-00805f9b34fb";

  const SPO2ServusesUUID = "46a970e0-0d5f-11e2-8b5e-0002a5d5c51b";
  const Oimetrychracteristic = "0aad7ea0-0d60-11e2-8e3c-0002a5d5c51b";

  const [hearRate, setHeartRate] = useState(0);
  const [spo2, setSp02] = useState(0);

  var Device;
  var Server;

  const requestPermission = async () => {
    // alert("we will use the bluetooth port for this activity");
    Device = await navigator.bluetooth
      .requestDevice({
        filters: [{ namePrefix: "Nonin3150" }],
        optionalServices: [
          "46a970e0-0d5f-11e2-8b5e-0002a5d5c51b",
          "0aad7ea0-0d60-11e2-8e3c-0002a5d5c51b",
          "0000180d-0000-1000-8000-00805f9b34fb",
          "00002a37-0000-1000-8000-00805f9b34fb",
        ],
      })
      .then((device) => {
        return device;
      });

 
    //  connect()
  };

  const connecttoDevice = async () => {

    Server = await Device.gatt.connect();

    const service = await Server.getPrimaryService(
      "0000180d-0000-1000-8000-00805f9b34fb"
    );

    const spo2Service = await Server.getPrimaryService(
      "46a970e0-0d5f-11e2-8b5e-0002a5d5c51b"
    );

    // console.log(spo2Service)

    const toggleHRCharacteristic = await service?.getCharacteristic(
      "00002a37-0000-1000-8000-00805f9b34fb"
    );

    const toggleSPO2Characteristic = await spo2Service?.getCharacteristic(
      "0aad7ea0-0d60-11e2-8e3c-0002a5d5c51b"
    );

    // https://developer.chrome.com/articles/bluetooth/
    if (toggleHRCharacteristic.properties.notify) {
      toggleHRCharacteristic.addEventListener(
        "characteristicvaluechanged",
        async (event) => {
          var buffer = viewBufferAsUint8Array(event.target.value);
          //  console.log(buffer[1])
          setHeartRate(buffer[1]);
        }
      );
      await toggleHRCharacteristic.startNotifications();
    }

    if (toggleSPO2Characteristic.properties.notify) {
      toggleSPO2Characteristic.addEventListener(
        "characteristicvaluechanged",
        async (event) => {
          // console.log(event.target.value)
          var buffer = viewBufferAsUint8Array(event.target.value);
          //    console.log(buffer[7])
          setSp02(buffer[7]);
        }
      );
      await toggleSPO2Characteristic.startNotifications();
    }
  };

  function viewBufferAsUint8Array(buf) {
    return new Uint8Array(buf.buffer);
  }

  useEffect(() => {}, []);

  return (
    <div>
      <button onClick={requestPermission}>find your device </button>
      <button onClick={connecttoDevice}>connect to device</button>
      <h1>{spo2}</h1>
      <h1>{hearRate}</h1>
    </div>
  );
}

export default BluetoothConnection;
