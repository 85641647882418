import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import HeartRateLevelCard from "./HeartRateLevelCard";
import style from "./HeartRateLevel.module.css";
import { Line } from "react-chartjs-2";
import HightHeartRateDuration from "./HightHeartRateDuration";
import AlertRecord from "./AlertRecord";
const { config } = require("../setting");

const HeartRateLevelAlert = (props) => {
  const { id } = useParams();

  const [levelArray, setLevelArray] = useState([]);

  const [showForm, setShowForm] = useState(false);

  const [levelValue, setLevelValue] = useState(0);
  const [actionType, setActionType] = useState("");
  const [Content, setContent] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");
  const [macErrorMessage, setMacErorrMessage] = useState("");

  const [contactTag, setContactTage] = useState("");
  const [contactEmail, setContactEamil] = useState("");
  const [contactContent, setcontactContent] = useState("");
  const [macaddressObject, setMacAddressObject] = useState({});

  const [macAddress, setMacAddress] = useState("");
  const [editMacAddress, setEditMacAddress] = useState("");
  const [showMacEditForm, setShowMacEditForm] = useState(false);

  const [heartRateRecord, setHeartRateRecord] = useState([]);
  const [time, setTime] = useState([]);

  const [alertRecords, SetAlertRecords] = useState([]);
  const [repeatLists, SetRepeatLists] = useState([]);

  const [repeatValue, setRepeatValue] = useState(0);
  const [repeatContent, setRepeatContent] = useState("");
  const [repeatArray, setRepeatArray] = useState([]);
  const [showRepeatEdit, setShowReapeatEdit] = useState(false);
  const [editRepeatValue, setEditRepeatValue] = useState(0);
  const [editRepeatContent, setEditRepeatContent] = useState("")
  const [repeatValueData, setRepeatValueData] = useState(0)
  const [repeatContentData, setRepeatContentData] = useState("")

  const getRepeatList = async () => {
    await axios
      .get(`${config.url.API_URL}/action/repeat/0/day/${id}`)
      .then(async (res) => {
        // console.log(res.data);
        SetRepeatLists(res.data);
      });
  };

  const getActionList = async () => {
    await axios
      .get(`${config.url.API_URL}/action/0/day/${id}`)
      .then(async (res) => {
        // console.log(res.data);
        SetAlertRecords(res.data);
      });
  };

  const [chartDataOne, setChartDataOne] = useState({
    labels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    datasets: [
      {
        label: "final three",
        data: [0],
        backgroundColor: ["rgba(255, 206, 86, 0.6)"],
        // borderWidth: 4,
      },
    ],
  });

  const [chartDataTwo, setChartDataTwo] = useState({
    labels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    datasets: [
      {
        label: "final two",
        data: [0],
        backgroundColor: ["rgba(255, 206, 86, 0.6)"],
        // borderWidth: 4,
      },
    ],
  });

  const [chartDataThree, setChartDataThree] = useState({
    labels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    datasets: [
      {
        label: "final one",
        data: [0],
        backgroundColor: ["rgba(255, 206, 86, 0.6)"],
        // borderWidth: 4,
      },
    ],
  });

  var chartTime = 30;

  const getUserLevel = async () => {
    await axios
      .get(`${config.url.API_URL}/Heart_Rate_Alart_Value/${id}`)
      .then(async (res) => {
        var levelArray = [];
        var repeatArray = [];
        res.data.map((item) => {
          if (item.action_type !== "repeatAlert") {
            levelArray.push(item);
          } else {
            // console.log(item);
            repeatArray.push(item);
            setEditRepeatValue(item.level_value)
            setEditRepeatContent(item.content)
            setRepeatValueData(item.level_value)
            setRepeatContentData(item.content)
          }
        });
        setLevelArray(levelArray);
        setRepeatArray(repeatArray);
      });
  };

  const getUserWatch = async () => {
    await axios.get(`${config.url.API_URL}/watch/patient/${id}`).then((res) => {
      // console.log(res.data);
      if (res.data !== null) {
        setMacAddressObject(res.data);
        setEditMacAddress(res.data.mac_id);
      }
    });
  };

  const AddLevel = async () => {
    if (actionType == "Email/Text" && contactEmail == "") {
      setErrorMessage("Email type need to unless have one email address");
    } else {
      await axios
        .post(`${config.url.API_URL}/Heart_Rate_Alart_Value`, {
          PID: id,
          level_value: levelValue,
          action_type: actionType,
          content: Content,
        })
        .then(async (response) => {
          var AlertContact = [];

          var responseData = response.data;

          if (actionType == "Email/Text") {
            await axios
              .post(`${config.url.API_URL}/contact/add`, {
                level_id: response.data.id,
                tag: contactTag,
                email: contactEmail,
                content: contactContent,
              })
              .then((res) => {
                AlertContact.push(res.data);
                responseData.alert_contact = AlertContact;
                // console.log(responseData);
              })
              .catch((err) => {
                console.log(err);
              });
          }

          setLevelArray((prevArray) => [...prevArray, responseData]);
          setShowForm(false);
          setErrorMessage("");
          setActionType(0);
          setActionType("");
          setErrorMessage("");
        })
        .catch((err) => {
          setErrorMessage(err.response.data.error);
          // console.log(err.response.data.error);
        });
    }
  };

  const SubmitRepeat = async () => {
    
      await axios
        .post(`${config.url.API_URL}/Heart_Rate_Alart_Value`, {
          PID: id,
          level_value: repeatValue,
          action_type: 'repeatAlert',
          content: repeatContent,
        })
        .then(async (response) => {
          // console.log(response.data)
          setRepeatValueData(response.data.level_value)
          setRepeatContentData(response.data.content)
          setRepeatArray([{"level_value": response.data.level_value, "content": response.data.content}])
        })
        .catch((err) => {
          setErrorMessage(err.response.data.error);
         
        });
  
  };

  const deleteLevelData = async (level_id) => {
    await axios
      .put(
        `${config.url.API_URL}/Heart_Rate_Alart_Value/delete/${level_id}`,
        {}
      )
      .then((res) => {
        setLevelArray(levelArray.filter((item) => item.id !== level_id));
      });
  };

  const deleteRepeatData = async (level_id) => {
    await axios
      .put(
        `${config.url.API_URL}/Heart_Rate_Alart_Value/delete/${level_id}`,
        {}
      )
      .then((res) => {
        setRepeatArray([]);
      });
  };

  const delectMacAddress = async (id) => {
    await axios
      .put(`${config.url.API_URL}/watch/delete/${id}`, {
        mac_id: macAddress,
      })
      .then((res) => {
        // console.log(res.data);
        setMacAddressObject({});
      });
  };

  const registerMacAddress = async () => {
    await axios
      .post(`${config.url.API_URL}/watch/add`, {
        p_id: id,
        mac_id: macAddress,
      })
      .then((response) => {
        setMacAddressObject(response.data);
      })
      .catch((err) => {
        console.log(err);
        setMacErorrMessage(err.response.data.error);
      });
  };

  const EditMacAddressfun = async (id) => {
    await axios
      .put(`${config.url.API_URL}/watch/edit/${id}`, {
        mac_id: editMacAddress,
      })
      .then((res) => {
        // console.log(res.data);
        setMacAddressObject(res.data);
        setShowMacEditForm(false);
      });
  };

  const updateRepeatData = async (id) => {
    
      await axios.put(
          `${config.url.API_URL}/Heart_Rate_Alart_Value/edit/${id}`,
          {
            level_value: editRepeatValue,
            action_type: "repeatAlert",
            content: editRepeatContent,
          }
        )
        .then(async (res) => {
          setRepeatValueData(res.data.level_value)
          setRepeatContentData(res.data.content)
          setShowReapeatEdit(false)
        });
  };




  const getUserHeartRateRecord = async () => {
    const hr_data_time = [];
    const hr_data_1 = [];
    const hr_data_2 = [];
    const hr_data_3 = [];

    var hr_CreateTime_1 = "";
    var hr_CreateTime_2 = "";
    var hr_CreateTime_3 = "";

    for (var i = 0; i < chartTime; i++) {
      hr_data_time.push(i);
    }

    await axios.get(`${config.url.API_URL}/hr_data/${id}`).then(async (res) => {
      // console.log(res.data);

      if (res.data != null && res.data != undefined && res.data.length >= 1) {
        setHeartRateRecord(res.data);

        const dataOne = res.data[0].hr_data;

        const finalOneTime = res.data[0].createdAt;
        var ts = new Date(finalOneTime);
        hr_CreateTime_1 = ts.toLocaleString();

        for (var one = 0; one < dataOne.length; one++) {
          hr_data_1.push(dataOne[one].heartRate);
        }

        setChartDataThree({
          labels: hr_data_time,
          datasets: [
            {
              label: hr_CreateTime_1,
              data: hr_data_1,
              backgroundColor: ["rgba(255, 206, 86, 0.6)"],
              borderWidth: 4,
            },
          ],
        });

        if (res.data.length >= 2) {
          const dataTwo = res.data[1].hr_data;

          const finalTwoTime = res.data[1].createdAt;
          var ts2 = new Date(finalTwoTime);
          hr_CreateTime_2 = ts2.toLocaleString();

          for (var two = 0; two < dataTwo.length; two++) {
            hr_data_2.push(dataTwo[two].heartRate);
          }

          setChartDataTwo({
            labels: hr_data_time,
            datasets: [
              {
                label: hr_CreateTime_2,
                data: hr_data_2,
                backgroundColor: ["rgba(255, 206, 86, 0.6)"],
                borderWidth: 4,
              },
            ],
          });

          if (res.data.length >= 3) {
            const dataTree = res.data[2].hr_data;

            const finalThreeTime = res.data[2].createdAt;
            var ts3 = new Date(finalThreeTime);
            hr_CreateTime_3 = ts3.toLocaleString();

            for (var three = 0; three < dataTree.length; three++) {
              hr_data_3.push(dataTree[three].heartRate);
            }

            setChartDataOne({
              labels: hr_data_time,
              datasets: [
                {
                  label: hr_CreateTime_3,
                  data: hr_data_3,
                  backgroundColor: ["rgba(255, 206, 86, 0.6)"],
                  borderWidth: 4,
                },
              ],
            });
          }
        }
      }
    });
  };

  useEffect(() => {
    getUserWatch();
    getUserLevel();
    getUserHeartRateRecord();
    getRepeatList();
    getActionList();
  }, []);

  return (
    <div>
      <div className={style.title}>
        <h2>Patient Smart Watch</h2>
      </div>
      {Object.keys(macaddressObject).length <= 0 ? (
        <div className={style.macAddreeinputContainer}>
          <h4>patient do not have a register watch yet</h4>
          <div className="input-group mb-2">
            <div className="input-group-prepend">
              <div className="input-group-text">Watch Mac Address</div>
            </div>
            <input
              type="text"
              value={macAddress}
              onChange={(e) => setMacAddress(e.target.value)}
              className="form-control"
              id="inlineFormInputGroup"
              placeholder="ex: AA:BB:CC:11:22:33"
            />
            <button
              onClick={() => registerMacAddress()}
              type="submit"
              className="btn btn-primary"
            >
              Add
            </button>
          </div>
        </div>
      ) : (
        <div style={{ textAlign: "center" }}>
          <h2>Patient registered watch mac address </h2>
          <h5>{macaddressObject.mac_id}</h5>
          {showMacEditForm ? (
            <div className={style.editMacAddressContainer}>
              <div className="input-group mb-2">
                <input
                  type="text"
                  value={editMacAddress}
                  onChange={(e) => setEditMacAddress(e.target.value)}
                  className="form-control"
                  id="inlineFormInputGroup"
                  placeholder="ex: AA:BB:CC:11:22:33"
                />
                <button
                  onClick={() => EditMacAddressfun(macaddressObject.id)}
                  type="submit"
                  className="btn btn-primary"
                >
                  update
                </button>
              </div>
            </div>
          ) : null}
          <div>
            <button
              type="button"
              onClick={(e) => {
                setShowMacEditForm(!showMacEditForm);
              }}
              className="btn btn-primary"
            >
              edit
            </button>
            <button
              type="button"
              onClick={(e) => {
                delectMacAddress(macaddressObject.id);
              }}
              className="btn btn-danger"
            >
              delete
            </button>
          </div>
        </div>
      )}
      <div className={style.heartRateRecordContainer}>
        <h2> Patient's last three data </h2>
        <div className={style.lineChartContainer}>
          <Line
            data={chartDataOne}
            options={{
              responsive: true,
              title: { text: "Latest first Heart Rate Record", display: true },
              animation: { duration: 0 },
              scales: {
                y: {
                  min: 50,
                  max: 200,
                },
              },
            }}
          />
        </div>
        <div className={style.lineChartContainer}>
          <Line
            data={chartDataTwo}
            options={{
              responsive: true,
              title: { text: "Latest first Heart Rate Record", display: true },
              animation: { duration: 0 },
              scales: {
                y: {
                  min: 50,
                  max: 200,
                },
              },
            }}
          />
        </div>
        <div className={style.lineChartContainer}>
          <Line
            data={chartDataThree}
            options={{
              responsive: true,
              title: { text: "Latest first Heart Rate Record", display: true },
              animation: { duration: 0 },
              scalse: {
                x: {
                  type: "time",
                  display: true,
                  title: { display: true, text: "time" },
                },
                y: {
                  display: true,
                  title: { display: true, text: "speed" },
                  beginAtZero: true,
                },
              },
              scales: {
                y: {
                  min: 50,
                  max: 200,
                },
              },
            }}
          />
        </div>
      </div>
      <div className={style.durationContainer}>
        <h2>High Heart Rate Duration</h2>
        <table className={style.durationTable}>
          <thead style={{ backgroundColor: "#429eff" }}>
            <tr>
              <th className={style.durationth}>heart Rate target</th>
              <th className={style.durationth}>Start time</th>
              <th className={style.durationth}>duration</th>
            </tr>
          </thead>
          <tbody>
            {repeatLists.length > 0 || repeatLists !== undefined ? (
              repeatLists.map((repeat) => {
                return (
                  <HightHeartRateDuration key={repeat.id} repeat={repeat} />
                );
              })
            ) : (
              <div>No data for now</div>
            )}
          </tbody>
        </table>
      </div>
      <div className={style.durationContainer}>
        <h2>Action Take record</h2>
        <table className={style.durationTable}>
          <thead style={{ backgroundColor: "#429eff" }}>
            <tr>
              <th className={style.durationth}></th>
              <th className={style.durationth}>Heart Patient Rate</th>
              <th className={style.durationth}>request action Type</th>
              <th className={style.durationth}>happend time</th>
            </tr>
          </thead>
          <tbody>
            {alertRecords.length > 0 || alertRecords !== undefined ? (
              alertRecords.map((record) => {
                return <AlertRecord key={record.id} record={record} />;
              })
            ) : (
              <div>No data for now</div>
            )}
          </tbody>
        </table>
      </div>
      <div className={style.title}>
        <h2>Heart Rate over value repeat alert</h2>
      </div>
      <div className={style.levelContainer}>
        <table className="table">
          <thead>
            <tr>
              <th style={{ width: "25%" }}>set heart rate boarder</th>
              <th style={{ width: "60%" }}>suggusetion content</th>
              <th style={{ width: "5%" }}></th>
            </tr>
          </thead>
          <tbody>
            {repeatArray.length > 0 ? (
              <>
                {repeatArray.map((repeat) => {
                  return (
                    <>
                      <tr key={repeat.id}>
                        <td>{repeatValueData}</td>
                        <td>{repeatContentData}</td>
                        <td>
                          <button
                            type="button"
                            onClick={(e) => {
                              setShowReapeatEdit(!showRepeatEdit);
                            }}
                            className="btn btn-primary"
                          >
                            edit
                          </button>
                        </td>
                        <td>
                          <button
                            type="button"
                            onClick={(e) => {
                              deleteRepeatData(repeat.id);
                            }}
                            className="btn btn-danger"
                          >
                            delete
                          </button>
                        </td>
                      </tr>
                      {showRepeatEdit ? (
                        <tr>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              value={editRepeatValue}
                              onChange={(e) => setEditRepeatValue(e.target.value)}
                              aria-label=""
                              aria-describedby="basic-addon1"
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              value={editRepeatContent}
                              onChange={(e) => setEditRepeatContent(e.target.value)}
                              aria-label=""
                              aria-describedby="basic-addon1"
                            />
                          </td>
                          <td>
                            <button
                              onClick={() => updateRepeatData(repeat.id)}
                              type="submit"
                              className="btn btn-primary"
                            > update </button>
                          </td>
                        </tr>
                      ) : null}
                    </>
                  );
                })}
              </>
            ) : (
              <tr>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={repeatValue}
                    onChange={(e) => setRepeatValue(e.target.value)}
                    aria-label=""
                    aria-describedby="basic-addon1"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={repeatContent}
                    onChange={(e) => setRepeatContent(e.target.value)}
                    aria-label=""
                    aria-describedby="basic-addon1"
                  />
                </td>
                <td>
                  <input
                    className="btn btn-primary btn-block"
                    type="button"
                    onClick={() => SubmitRepeat()}
                    value="Add"
                  ></input>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className={style.title}>
        <h2>Smart watch Alert Handle</h2>
      </div>
      <div className={style.levelContainer}>
        {levelArray.length > 0 ? (
          <div>
            <table className="table">
              <thead>
                <tr>
                  <th style={{ width: "5%" }}>index</th>
                  <th style={{ width: "15%" }}>level value(above)</th>
                  <th style={{ width: "20%" }}>action type</th>
                  <th style={{ width: "50%", textAlign: "left" }}>
                    Send Out content
                  </th>
                  <th style={{ width: "5%" }}></th>
                  <th style={{ width: "5%" }}></th>
                </tr>
              </thead>

              <tbody>
                {levelArray.map((level, i) => {
                  return (
                    <HeartRateLevelCard
                      key={level.id}
                      index={i}
                      level={level}
                      deleteLevelData={deleteLevelData}
                    />
                  );
                })}
              </tbody>
            </table>
            <div className={style.buttonContainer}>
              <input
                className="btn btn-success btn-block"
                onClick={() => setShowForm(!showForm)}
                type="button"
                value="add more level"
              ></input>
            </div>
            {showForm ? (
              <>
                <table className="table">
                  <thead>
                    <tr>
                      <th>level value above</th>
                      <th>action type</th>
                      <th>Send Out content</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={levelValue}
                          onChange={(e) => setLevelValue(e.target.value)}
                          aria-label=""
                          aria-describedby="basic-addon1"
                        />
                      </td>
                      <td>
                        <div className="input-group mb-3">
                          <label
                            className="input-group-text"
                            htmlFor="inputGroupSelect01"
                          >
                            Type
                          </label>
                          <select
                            value={actionType}
                            onChange={(e) => setActionType(e.target.value)}
                            defaultValue={"Small Vegetables"}
                            className="form-control"
                            id="inputGroupSelect01"
                          >
                            <option defaultValue="">choose one type</option>
                            <option value="Email/Text">Email or Text</option>
                            <option value="status check">Status Check</option>
                            <option value="sugguestion">Sugguestion</option>
                          </select>
                        </div>
                        {/* <input type="text" className="form-control" value={actionType} onChange={(e) => setActionType(e.target.value)} aria-label="" aria-describedby="basic-addon1"/> */}
                      </td>
                      <td>
                        {actionType != "Email/Text" ||
                        actionType != "sugguestion" ? (
                          <input
                            type="text"
                            className="form-control"
                            value={Content}
                            onChange={(e) => setContent(e.target.value)}
                            aria-label=""
                            aria-describedby="basic-addon1"
                          />
                        ) : null}
                      </td>
                    </tr>
                    {actionType == "Email/Text" ? (
                      <tr>
                        <td>
                          <div className="input-group mb-2">
                            <div className="input-group-prepend">
                              <div className="input-group-text">tag</div>
                            </div>
                            <input
                              type="text"
                              value={contactTag}
                              onChange={(e) => setContactTage(e.target.value)}
                              className="form-control"
                              id="inlineFormInputGroup"
                              placeholder=""
                            />
                          </div>
                        </td>
                        <td>
                          <label
                            className="sr-only"
                            htmlFor="inlineFormInputGroup"
                          ></label>
                          <div className="input-group mb-2">
                            <div className="input-group-prepend">
                              <div className="input-group-text">email</div>
                            </div>
                            <input
                              type="text"
                              value={contactEmail}
                              onChange={(e) => setContactEamil(e.target.value)}
                              className="form-control"
                              id="inlineFormInputGroup"
                              placeholder=""
                            />
                          </div>
                        </td>
                        <td>
                          <label
                            className="sr-only"
                            htmlFor="inlineFormInputGroup"
                          ></label>
                          <div className="input-group mb-2">
                            <div className="input-group-prepend">
                              <div className="input-group-text">content</div>
                            </div>
                            <input
                              type="text"
                              value={contactContent}
                              onChange={(e) =>
                                setcontactContent(e.target.value)
                              }
                              className="form-control"
                              id="inlineFormInputGroup"
                              placeholder=""
                            />
                          </div>
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
                <p>{ErrorMessage}</p>
                <div className={style.AddsubmitBtn}>
                  <input
                    className="btn btn-primary btn-block"
                    onClick={() => AddLevel()}
                    type="button"
                    value="Add"
                  ></input>
                </div>
              </>
            ) : (
              <div></div>
            )}
          </div>
        ) : (
          <div>
            <h2>we haven't set the heart Rate alert for this patient</h2>
            <table className="table">
              <thead>
                <tr className={style.duationTHead}>
                  <th>level value above</th>
                  <th>action type</th>
                  <th>Send Out content</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      value={levelValue}
                      onChange={(e) => setLevelValue(e.target.value)}
                      aria-label=""
                      aria-describedby="basic-addon1"
                    />
                  </td>
                  <td>
                    <div className="input-group mb-3">
                      <select
                        value={actionType}
                        onChange={(e) => setActionType(e.target.value)}
                        className="form-control"
                      >
                        <option defaultValue="">choose one type</option>
                        <option value="Email/Text">Email or Text</option>
                        <option value="status check">Status Check</option>
                        <option value="sugguestion">Sugguestion</option>
                      </select>
                    </div>
                    {/* <input type="text" className="form-control" value={actionType} onChange={(e) => setActionType(e.target.value)} aria-label="" aria-describedby="basic-addon1"/> */}
                  </td>
                  <td>
                    {actionType != "Email/Text" ? (
                      <input
                        type="text"
                        className="form-control"
                        value={Content}
                        onChange={(e) => setContent(e.target.value)}
                        aria-label=""
                        aria-describedby="basic-addon1"
                      />
                    ) : null}
                  </td>
                </tr>

                {actionType == "Email/Text" ? (
                  <tr>
                    <td>
                      <div className="input-group mb-2">
                        <div className="input-group-prepend">
                          <div className="input-group-text">tag</div>
                        </div>
                        <input
                          type="text"
                          value={contactTag}
                          onChange={(e) => setContactTage(e.target.value)}
                          className="form-control"
                          id="inlineFormInputGroup"
                          placeholder=""
                        />
                      </div>
                    </td>
                    <td>
                      <label
                        className="sr-only"
                        htmlFor="inlineFormInputGroup"
                      ></label>
                      <div className="input-group mb-2">
                        <div className="input-group-prepend">
                          <div className="input-group-text">email</div>
                        </div>
                        <input
                          type="text"
                          value={contactEmail}
                          onChange={(e) => setContactEamil(e.target.value)}
                          className="form-control"
                          id="inlineFormInputGroup"
                          placeholder=""
                        />
                      </div>
                    </td>
                    <td>
                      <label
                        className="sr-only"
                        htmlFor="inlineFormInputGroup"
                      ></label>
                      <div className="input-group mb-2">
                        <div className="input-group-prepend">
                          <div className="input-group-text">content</div>
                        </div>
                        <input
                          type="text"
                          value={contactContent}
                          onChange={(e) => setcontactContent(e.target.value)}
                          className="form-control"
                          id="inlineFormInputGroup"
                          placeholder=""
                        />
                      </div>
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
            <p>{ErrorMessage}</p>
            <div className={style.AddsubmitBtn}>
              <input
                className="btn btn-primary btn-block"
                onClick={() => AddLevel()}
                type="button"
                value="Add"
              ></input>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HeartRateLevelAlert;
