import React, { useState, useEffect } from "react";
import axios from "axios";
import fitbit_oauth from "./fitbit _oauth.png";
import fitbit_Sign_in from "./fitbit Sign In.png";
import style from "./FitbitManager.module.css";

const FitbitLoginPage = (props) => {
  return (
    <div className={style.FitbitLogin}>
      <h3>Welcome to Home Automated Telemanagement(HAT) device login</h3>
      <h5>
        By login in we will be able to collect your data inclouding daily step,
        calories, and sleep duration etc, please click the "Login to Fitbit"
        button below and fellow the instraction below to share the data with us
      </h5>
      
        <a
         
          href="https://www.fitbit.com/oauth2/authorize?response_type=code&client_id=23PHQJ&scope=activity+cardio_fitness+electrocardiogram+heartrate+location+nutrition+oxygen_saturation+profile+respiratory_rate+settings+sleep+social+temperature+weight&redirect_uri=https%3A%2F%2Ftele-manager.org%2Ffitbit%2Fdashboard"
        >
          <button  className={style.loginbtn}>login to Fitbit</button>
        </a>
    
      <div className={style.instractionContainer}>
        <div className={style.imageContainer}>
          <img
            className={style.fitbit_instraction_image}
            src={fitbit_Sign_in}
          />
          <p>
            please click Continue with Google and login to the gmail we provide
          </p>
        </div>
        <div className={style.imageContainer}>
          <img className={style.fitbit_instraction_image} src={fitbit_oauth} />
          <p>check the Allow All check box and click "Allow" button</p>
        </div>
      </div>
    </div>
  );
};

export default FitbitLoginPage;
