import React, { act, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Webcam from "react-webcam";
import { Camera } from "@mediapipe/camera_utils";
import {
  FACEMESH_TESSELATION,
  HAND_CONNECTIONS,
  Holistic,
  Results,
  POSE_CONNECTIONS,
} from "@mediapipe/holistic";
import { drawConnectors, drawLandmarks } from "@mediapipe/drawing_utils";
import style from "./PostDection.module.css";
import axios from "axios";
import finishUrl from './success.mp3'


function MediapipeMLPostBarLift() {
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  var defaultSetCountDown = 60 

  const formData = new FormData();

  const [count, setCount] = useState(0);
  const [action, setAction] = useState("");
  const [Timecounter, setTimeCounter] = useState(defaultSetCountDown);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [process, setProcess] = useState("Start Exercise")
  const [playing, toggle] = useAudio(finishUrl);
  const [array, setArray] = useState([])

  var isStanding = true;
  var isLiftDown = true;
  var sitCount = 0;
  var liftUpCount = 0;
  var timeCount = 0;
  const exerciseArray = []

  const onResults = (results) => {
    if (!webcamRef.current?.video || !canvasRef.current) return;
    const videoWidth = webcamRef.current.video.videoWidth;
    const videoHeight = webcamRef.current.video.videoHeight;
    canvasRef.current.width = videoWidth;
    canvasRef.current.height = videoHeight;

    const canvasElement = canvasRef.current;
    const canvasCtx = canvasElement.getContext("2d");

    if (canvasCtx == null) throw new Error("Could not get context");
    canvasCtx.save();
    canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);

    // Only overwrite existing pixels.
    canvasCtx.globalCompositeOperation = "source-in";
    canvasCtx.fillRect(0, 0, canvasElement.width, canvasElement.height);

    // Only overwrite missing pixels.
    canvasCtx.globalCompositeOperation = "destination-atop";
    canvasCtx.drawImage(
      results.image,
      0,
      0,
      canvasElement.width,
      canvasElement.height
    );

    canvasCtx.globalCompositeOperation = "source-over";
    drawConnectors(canvasCtx, results.poseLandmarks, POSE_CONNECTIONS, {
      color: "#00FF00",
      lineWidth: 1,
    });
    drawLandmarks(canvasCtx, results.poseLandmarks, {
      color: "#FF0000",
      lineWidth: 1,
      radius: 1,
    });

    var landmark = [];
    if (results.poseLandmarks) {
      landmark = results.poseLandmarks;
    }

    if (landmark.length > 0) {
      var right_shoulder = results.poseLandmarks[11];
      var right_hip = results.poseLandmarks[23];
      var right_knee = results.poseLandmarks[25];

      var left_shoulder = results.poseLandmarks[12];
      var left_hip = results.poseLandmarks[24];
      var left_knee = results.poseLandmarks[26];

      // DetectAction(landmark)
      if (
        (right_shoulder.visibility > 0.6 || left_shoulder.visibility > 0.6) &&
        (left_knee.visibility > 0.6 || right_knee.visibility > 0.6)
      ) {
     
        DetectActionliftUpandDown(landmark)
       
      } else {
        setAction("No detect");
      }
      // console.log(landmark);
    }

    //   console.log(results)
    // drawConnectors(canvasCtx, results.faceLandmarks, FACEMESH_TESSELATION,
    //   {color: '#C0C0C070', lineWidth: 1});
    // drawConnectors(canvasCtx, results.leftHandLandmarks, HAND_CONNECTIONS,
    //   {color: '#CC0000', lineWidth: 5});
    // drawLandmarks(canvasCtx, results.leftHandLandmarks,
    //   {color: '#00FF00', lineWidth: 2});
    // drawConnectors(canvasCtx, results.rightHandLandmarks, HAND_CONNECTIONS,
    //   {color: '#00CC00', lineWidth: 5});
    // drawLandmarks(canvasCtx, results.rightHandLandmarks,
    //   {color: '#FF0000', lineWidth: 2});
    canvasCtx.restore();
  };


  const DetectActionliftUpandDown = async (landmark) => {
    // console.log(landmark)
    // formData.append('landmark', landmark)
    // console.log(formData.landmark);
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["Accept"] = "application/json";
    // console.log(landmark)

    axios
      .post("https://agile-island-96803-e8e6e1c03a98.herokuapp.com/lift_bar/", { landmark: landmark })
      .then((response) => {
        if (response.data != "undefined") {
          if (response.data[0].action != "undefined") {
            console.log(response.data[0].action)
            var detectAction = response.data[0].action;

            if (detectAction == "Down") {
              isLiftDown = true;
            } else if (detectAction == "Up" && isLiftDown == true) {
              liftUpCount++;
              setCount(liftUpCount);
              isLiftDown = false;
              const exerciseValue = {
                count: liftUpCount,
                timeStamp: Date.now()
              }
              exerciseArray.push(exerciseValue)
              console.log(exerciseArray)
              setArray(exerciseArray);
            }

            setAction(detectAction);
          }
        }
      });
  };

  const StartExercise = () => {
    timeCount = defaultSetCountDown;
    setCount(0);
    setButtonDisabled(true)
    setProcess('Detecting...');
    // exerciseArray = []
    const interval = setInterval(() => {
      if (timeCount > 0 ) {
        
        timeCount--;
        setTimeCounter(timeCount);
      }else{
        toggle()
        console.log(array)
        console.log(exerciseArray)
        setButtonDisabled(false)
        setProcess('Restart Exercise');
        return clearInterval(interval);
      }
    }, 1000);

    if (timeCount ==  0) {
    
    }
  };

  useEffect(() => {
    const holistic = new Holistic({
      locateFile: (file) => {
        return `https://cdn.jsdelivr.net/npm/@mediapipe/holistic/${file}`;
      },
    });
    holistic.setOptions({
      selfieMode: true,
      modelComplexity: 1,
      smoothLandmarks: true,
      enableSegmentation: true,
      smoothSegmentation: true,
      refineFaceLandmarks: true,
      minDetectionConfidence: 0.5,
      minTrackingConfidence: 0.5,
    });

    holistic.onResults(onResults);

    if (
      typeof webcamRef.current !== "undefined" &&
      webcamRef.current !== null
    ) {
      if (!webcamRef.current?.video) return;
      const camera = new Camera(webcamRef.current.video, {
        onFrame: async () => {
          if (!webcamRef.current?.video) return;
          await holistic.send({ image: webcamRef.current.video });
        },
        width: 640,
        height: 480,
      });
      camera.start();
    }
  }, []);

  return (
    <div className={style.MLContainer}>
      <div className={style.cameraSpace}>
        <Webcam
          ref={webcamRef}
          style={{
            position: "absolute",
            marginLeft: "auto",
            marginRight: "auto",
            left: 0,
            right: 0,
            textAlign: "center",
            zIndex: 9,
            width: 840,
            height: 560,
          }}
        />
        <canvas
          ref={canvasRef}
          style={{
            position: "absolute",
            marginLeft: "auto",
            marginRight: "auto",
            left: 0,
            right: 0,
            textAlign: "center",
            zIndex: 9,
            width: 840,
            height: 560,
          }}
        />
      </div>
      <div className={style.ActionNotifyContainer}>
        <div className={style.ActionBtnContainer}>
          <Link to="/media_pose_ml_detection/sit_and_stand" className={style.ActionBtn}>Sit and Stand</Link>
          <Link className={style.ActionBtnDisable}>Lift Bar</Link>
        </div>
        <div className={style.notifiyActionTextBox}>
          <div>
            <h1>Exercise : Bar Lifting</h1>
            <h1>Timer : {Timecounter} second</h1>
            <h1>Action: {action}</h1>
            <h1>Action Count: {count}</h1>
          </div>
          <div className={style.exeBtn}>
            <div>{}</div>
            <button
              disabled={isButtonDisabled}
              className={style.gridItem}
              onClick={() => {
                StartExercise();
              }}
            >
              {process}
            </button>
            <div>{}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

const useAudio = url => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
      playing ? audio.play() : audio.pause();
    },
    [playing]
  );

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, []);

  return [playing, toggle];
};

export default MediapipeMLPostBarLift;
