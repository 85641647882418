import React, { useState, useEffect } from "react";
import axios from "axios";



const Fitbit_Week_card = (props) => {

    const [updateWeekValue , setUpdateWeekValue] = useState(props.week)
    const [notify, setNotify] = useState("")

    return (
       
            <tr>
            <td>
              Week {props.index+1}
            </td>
            <td>
            <input
                  type="text"
                  className="form-control"
                  value={updateWeekValue}
                  onChange={(e) => setUpdateWeekValue(e.target.value)}
                  aria-label=""
                  aria-describedby="basic-addon1"
                />
            </td>
            <td>
              <button
                type="button"
                onClick={() => {
                    const newValue = updateWeekValue;
                    props.editWeekValue(props.index, newValue);
                    setNotify("update")
                }}
                className="btn btn-primary"
              >
                edit
              </button>
            </td>
            <td>
              {notify}
            </td>
           
          </tr>
    )
}

export default Fitbit_Week_card;