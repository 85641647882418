import React, { useState, useEffect } from "react";
import GridLoader from "react-spinners/GridLoader";
import axios from "axios";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import { useParams } from "react-router-dom";
import style from "./FirebaseHook.module.css";
import { config } from "./setting";

const InfoTitleCard = (props) => {
  const [hearRate, setHeartRate] = useState(0);
  const [spo2, setSp02] = useState(0);
  const [device, setDevice] = useState({});
  const [session_id, setSession_id] = useState("");

  var Device;
  const [show, setShow] = useState("connect");

  const [PrescriptionArray, setPrescriptionArray] = useState([]);
  const [runTimeArray, setRunTimeArray] = useState([]);
  const [curcelTime, setCurcelTime] = useState(3);

  const [storeArray, setStorArray] = useState([]);

  const [CBdata, setCBdata] = useState(0);
  const [isStore, setIsStore] = useState(false);

  const [loading, setLoading] = useState(true);

  const [chartData, setChartData] = useState({
    labels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    datasets: [
      {
        label: "Prescription",
        data: [0],
        backgroundColor: ["rgba(255, 206, 86, 0.6)"],
        borderWidth: 4,
      },
      {
        label: "Your Speed",
        data: [0],
        backgroundColor: ["rgba(75, 192, 192, 0.6)"],
        borderWidth: 4,
      },
    ],
  });

  const { id } = useParams();

  let time = [];
  let armbikeSpeed = [];
  let storeData = [];

  let presRPM = [];
  let returnbikeName = "";
  let returnNonine = "";
  var prevalue = 0;

  var HR = 0;
  var SPO2 = 0;
  const configheader = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    },
  };

  const contentShow = () => {
    if (show == "connect") {
      return (
        <button className={style.connectbtn} onClick={connecttoDevice}>
          Start
        </button>
      );
    } else if (show == "loading") {
      return <div style={{width:"100px"}}><GridLoader loading={loading} size={10} color="#36d7b7" /></div>
    } else if (show == "exercise") {
      return <p className={style.statusText}> Go!!</p>;
    } else if (show == "finish") {
      return <p className={style.statusText}>Finish</p>;
    }
  };

  const SetLable = async () => {
    time = [];
    var count = 0;
    var Prescription = [];
    var patientId = id;
    var userBike = "";

    var Prescrip = await axios
      .get(`${config.url.API_URL}/Arm_Bike_Pre_Sessions/${patientId}`)
      .then((res) => {
        // console.log(res.data[0].SESSION_ID);
        var p = res.data;
        setSession_id(res.data[0].SESSION_ID);
        for (var i = 0; i < res.data.length; i++) {
          var speed = p[i].INTERVAL_SPEED;
          var interval = p[i].INTERVAL_TIME;
          Prescription.push({ speed: speed, interval: interval });
          for (var j = 0; j < interval; j++) {
            presRPM.push(speed);
            time.push(count);
            count++;
          }
        }

        userBike = axios
          .get(`${config.url.API_URL}/BikeID/${id}`)
          .then((res) => {
            const deviceData = res.data
            // console.log(deviceData)
            returnbikeName = res.data.BikeID;
            returnNonine = res.data.Nonin_mac_address
            setDevice(deviceData);
          });

        return userBike;
      });

    if (Prescrip != "") {
      // console.log(Prescription);
      // console.log(presRPM)
      setCurcelTime(count);
      // console.log(curcelTime)
      setPrescriptionArray(presRPM);
      setRunTimeArray(time);

      setChartData({
        labels: time,
        datasets: [
          {
            label: "Prescription",
            data: presRPM,
            backgroundColor: ["rgba(255, 206, 86, 0.6)"],
            borderWidth: 4,
          },
          // {
          //   label: "max=120 min=0",
          //   backgroundColor: ["rgba(75, 192, 192, 0)"],
          //   data: [0, 120],
          //   pointStyle: false,
          //   showLine: false,
          // },
        ],
      });
    }

    return true;
  };

  const getData = async () => {
    // console.log(device);
    var bike = device.BikeID;
    var Nonin = device.Nonin_mac_address;
    
    if (bike == "") {
      console.log("bike name is null")
      // bike = "cloudBike20";
      // Nonin = "Nonin3150_505845383"
    }else{
      // console.log(bike)
      // console.log(Nonin)
    }
    var timeremin = curcelTime;

    var CB;
    // console.log(timeremin)
    if (armbikeSpeed.length < curcelTime) {
      // console.log(
      //   `https://fitbit-mail-send-e67f3a8a278a.herokuapp.com/firebase/${id}/${bike}/${Nonin}`
      // );
      const exerciseObj = await axios
        .get(
          `https://fitbit-mail-send-e67f3a8a278a.herokuapp.com/firebase/${id}/${bike}/${Nonin}`,
          configheader
        )
        //const exerciseObj = await axios.get(`${config.url.API_URL}/firebase/${id}/${bike}`)
        .then(async (res) => {
          console.log(res.data);
          var speed = Math.ceil(res.data.Bike.speed);
          var NoninData = res.data.Nonin

          if (speed > 300) {
            speed = prevalue;
            armbikeSpeed.push(prevalue);
            CB = { time: new Date(), speed: speed, hearRate: NoninData.HeartRate, SPO2: NoninData.SPO2 };
            // storeData.push(CB);
            setCBdata(speed);
            setHeartRate(NoninData.HeartRate);
            setSp02(NoninData.SPO2);
          } else {
            CB = { time: new Date(), speed: speed, hearRate: NoninData.HeartRate, SPO2: NoninData.SPO2 };
            armbikeSpeed.push(speed);
            // storeData.push(CB);
            setCBdata(speed);
            setHeartRate(NoninData.HeartRate);
            setSp02(NoninData.SPO2);
          }

          prevalue = speed;
          // setStorArray((oldArray) => [...oldArray, CB]);
          // setCurcelTime(curcelTime - 1);
          // setCurcelTime(timeremin)
          // console.log(armbikeSpeed.length)
          return CB;
        });

      if (exerciseObj != null) {
        storeData.push(CB);
        setStorArray(storeData);
      }
    } else {
      setShow("finish");
      if (!isStore) {
        setStorArray(storeData);
        setIsStore(false);
      }
    }

    setChartData({
      labels: runTimeArray,
      datasets: [
        {
          label: "Prescription",
          data: PrescriptionArray,
          backgroundColor: ["rgba(255, 206, 86, 0.6)"],
          borderWidth: 4,
        },
        {
          label: "Your Speed",
          data: armbikeSpeed,
          backgroundColor: ["rgba(75, 192, 192, 0.6)"],
          borderWidth: 4,
        },
      ],
    });
  };

  const closeWindow = async () => {
    const exerciseObject = {
      P_id: id,
      SESSION_ID: session_id,
      MataData: storeArray,
      datetime: new Date(),
    };

    const promist = await axios
      .post(
        `${config.url.API_URL}/Arm_Bike_Exercise_Object/add`,
        exerciseObject
      )
      .then(function (response) {
        // console.log(response);
        return true;
      })
      .catch(function (error) {
        // console.log(error);
      });

    if (promist) {
      // console.log(exerciseObject);
      window.close();
    }

    // axios.post('http://localhost:5000/Arm_Bike_Exercise_Object/add', {})

    // window.close();
  };


  useEffect(() => {
    // if (SetLable()) {
      // console.log(presRPM);
      // console.log(returnbikeName);
      // const interval = setInterval(() => getData(), 1000);
      // return () => {
      //   clearInterval(interval);
      // };
    // }
    SetLable()
  }, []);

  //for bluetooth

  const requestPermission = async () => {
    // alert("we will use the bluetooth port for this activity");
    Device = await navigator.bluetooth
      .requestDevice({
        filters: [{ namePrefix: "Nonin3150" }],
        optionalServices: [
          "46a970e0-0d5f-11e2-8b5e-0002a5d5c51b",
          "0aad7ea0-0d60-11e2-8e3c-0002a5d5c51b",
          "0000180d-0000-1000-8000-00805f9b34fb",
          "00002a37-0000-1000-8000-00805f9b34fb",
        ],
      })
      .then((device) => {
        // console.log(device);
        return device;
      });

    setDevice(Device);
    setShow("connect");
  };

  const connecttoDevice = async () => {
    setShow("loading");

    // const server = await device.gatt.connect();

    // const service = await server.getPrimaryService(
    //   "0000180d-0000-1000-8000-00805f9b34fb"
    // );

    // const spo2Service = await server.getPrimaryService(
    //   "46a970e0-0d5f-11e2-8b5e-0002a5d5c51b"
    // );

    // // console.log(spo2Service)

    // const toggleHRCharacteristic = await service?.getCharacteristic(
    //   "00002a37-0000-1000-8000-00805f9b34fb"
    // );

    // const toggleSPO2Characteristic = await spo2Service?.getCharacteristic(
    //   "0aad7ea0-0d60-11e2-8e3c-0002a5d5c51b"
    // );

    // // https://developer.chrome.com/articles/bluetooth/
    // if (toggleHRCharacteristic.properties.notify) {
    //   toggleHRCharacteristic.addEventListener(
    //     "characteristicvaluechanged",
    //     async (event) => {
    //       var buffer = viewBufferAsUint8Array(event.target.value);
    //       //  console.log(buffer[1])
    //       HR = buffer[1];
    //       setHeartRate(buffer[1]);
    //     }
    //   );
    //   await toggleHRCharacteristic.startNotifications();
    // }

    // if (toggleSPO2Characteristic.properties.notify) {
    //   toggleSPO2Characteristic.addEventListener(
    //     "characteristicvaluechanged",
    //     async (event) => {
    //       // console.log(event.target.value)
    //       var buffer = viewBufferAsUint8Array(event.target.value);
    //       // console.log(buffer[7]);
    //       SPO2 = buffer[7];
    //       setSp02(buffer[7]);
    //     }
    //   );
    //   await toggleSPO2Characteristic.startNotifications();
    // }

    setShow("exercise");

    const interval = setInterval(() => getData(), 1100);
    return () => {
      clearInterval(interval);
    };
  };

  function viewBufferAsUint8Array(buf) {
    return new Uint8Array(buf.buffer);
  }

  return (
    <div className={style.background}>
      <div className={style.dataContainer}>
        <div>
          <div className={style.connectBtnContainer}>{contentShow()}</div>
          <div className={style.textDisplayContainer}>
            <div>
              <p className={style.labeltitle}>Speed</p>
              <p className={style.valueStyle}>{CBdata}</p>
            </div>
            <div>
              <p className={style.labeltitle}>SpO2</p>
              <p className={style.valueStyle}>{spo2}</p>
            </div>
            <div>
              <p className={style.labeltitle}>HR</p>
              <p className={style.valueStyle}>{hearRate}</p>
            </div>
          </div>
        </div>

        <div className={style.firebaselineChartContainer}>
          <Line
            data={chartData}
            options={{
              // responsive: true,
              // title: { text: "THICCNESS SCALE", display: true },
              animation: { duration: 0 },
              scalse: {
                x: {
                  type: "time",
                  display: true,
                  title: {
                    display: true,
                    text: "time",
                  },
                },
                y: {
                  display: true,
                  title: {
                    display: true,
                    text: "speed",
                  },
                  beginAtZero: true,
                },
              },
              scales: {
                y: {
                  min: 0,
                  max: 140,
                },
              },
            }}
          />
          <div className={style.btnContainer}>
            <button className={style.finishBtn} onClick={closeWindow}>
              Finish
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoTitleCard;
