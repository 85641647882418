import { FontSize } from 'ckeditor5'
import React, { Component } from 'react'

let dialogStyle = {
    width: '55%',
    maxWidth: "100%",
    margin: '0 auto',
    position: "fixed",
    left: "50%",
    top: '50%',
    transform: "translate(-50%,-50%)",
    zIndex: '999',
    // backgroundColor:'white',
    backgroundColor:"#eee",
    // background: '10px 20px 40px',
    borderRadius: "1px",
    display: "flex",
    fixDirection: "column",
    textAlign : "left",
    padding:"30px 20px",
    border: "solid"
   
}

let dialogCloseButtonStyles ={
    // marginBottom : '15px',
    margin: '5px 15px 15px 5px',
    padding: '3px 8px',
    cursor:'pointer',
    borderRadius:"50%",
    border:"none",
    width:'40px',
    height:'20px',
    fontWeight:'bold',
    alignSelf:'fiex-end',
    FontSize:'30px'
}

let content = {
    width: '85%',
    height: '40%'
}

class Dialog extends Component {

    render() {
        let dialog = (
            <div style={dialogStyle}>
                <button style={dialogCloseButtonStyles} onClick={this.props.onClose}>x</button>
                <div style={content}>{this.props.children}</div>
            </div>
        )
        
        if(!this.props.isOpen){
            dialog = null;
        }

        return (
           <div>
               {dialog}
           </div>
        )
    }
}

export default Dialog