const prod = {
  url: {
    API_URL:"https://tele-manager.org/api"
    // API_URL: "http://100.25.139.222:5000",
  },
};

const dev = {
  url: {
    API_URL: "http://localhost:5000",
  },
};

export const config = prod ;
