import React, { useState, useEffect } from "react";
import axios from "axios";
import { Line } from "react-chartjs-2";
import { useParams } from "react-router-dom";
import { config } from "../setting";
import style from "./ArmbikeDataDisplay.module.css";

const ArmBikeDataDisplay = (props) => {
  const { id } = useParams();
  const [session_id, setSession_id] = useState("");
  const [curcelTime, setCurcelTime] = useState(3);
  const [PrescriptionArray, setPrescriptionArray] = useState([]);
  const [runTimeArray, setRunTimeArray] = useState([]);

  const [dateList, setDateList] = useState([])
  const [selectDate, setSelectDate] = useState("")
  const [currentDisplayDate, setCurrentDisplayDate] = useState("")

  let time = [];
  let presRPM = [];
  let userData = [];
  let returnbikeName = "";

  const [chartData, setChartData] = useState({
    labels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    datasets: [
      {
        label: "Prescription",
        data: [0, 2, 4, 1, 2, 5, 3, 5, 3, 3, 4],
        backgroundColor: ["rgba(255, 206, 86, 0.6)"],
        borderWidth: 4,
      },
      {
        label: "Your Speed",
        data: [0, 2, 6, 3, 4, 7, 3],
        backgroundColor: ["rgba(75, 192, 192, 0.6)"],
        borderWidth: 4,
      },
    ],
  });

  const [NoninchartData, setNoninChartData] = useState({
    labels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    datasets: [
      {
        label: "Heart Rate",
        data: [0],
        backgroundColor: ["rgba(213, 14, 65, 0.6)"],
        borderWidth: 4,
      },
      {
        label: "spO2",
        data: [0],
        backgroundColor: ["rgba(75, 192, 192, 0.6)"],
        borderWidth: 4,
      },
    ],
  });

  const SetLable = async () => {
    time = [];
    var count = 0;
    var Prescription = [];
    var patientId = id;
    var userBike = "";

    var Prescrip = await axios
      .get(`${config.url.API_URL}/Arm_Bike_Pre_Sessions/${patientId}`)
      .then((res) => {
        console.log(res.data[0].SESSION_ID);
        var p = res.data;
        setSession_id(res.data[0].SESSION_ID);
        for (var i = 0; i < res.data.length; i++) {
          var speed = p[i].INTERVAL_SPEED;
          var interval = p[i].INTERVAL_TIME;
          Prescription.push({ speed: speed, interval: interval });
          for (var j = 0; j < interval; j++) {
            presRPM.push(speed);
            time.push(count);
            count++;
          }
        }
      });

    if (Prescrip != "") {
      setCurcelTime(count);
      setPrescriptionArray(presRPM);
      setRunTimeArray(time);

      setChartData({
        labels: time,
        datasets: [
          {
            label: "Prescription",
            data: presRPM,
            backgroundColor: ["rgba(255, 206, 86, 0.6)"],
            borderWidth: 4,
          },
        ],
      });
    }

    return true;
  };

  const getAllDates = async () => {
    var DateTimeList = await axios.get(
      `${config.url.API_URL}/Arm_Bike_Exercise_Object/all_date_value/${id}`
    );
    setDateList(DateTimeList.data.time)
    console.log(DateTimeList.data);
  };

  const getLastestArmBikeData = async () => {
    const chartLable = [];
    var ArmbikeSession = await axios.get(
      `${config.url.API_URL}/Arm_Bike_Exercise_Object/session/${id}`
    );

    var date = new Date(ArmbikeSession.data.date);
    date = date.toLocaleDateString(undefined, {day:'2-digit'}) + '-' + date.toLocaleDateString(undefined, {month:'short'}) + '-' + date.toLocaleDateString(undefined, {year:'numeric'} )  + '  ' + date.getHours(undefined, {hour:'numeric'}) + ':' + date.getMinutes(undefined, {hour:'numeric'}) 
   
    setCurrentDisplayDate(date)

    for (let i = 0; i < ArmbikeSession.data.bikeSpeed.length; i++) {
      chartLable.push(i);
    }

    setChartData({
      labels: time,
      datasets: [
        {
          label: "Speed",
          data: ArmbikeSession.data.bikeSpeed,
          backgroundColor: ["rgba(75, 192, 192, 0.6)"],
          borderWidth: 3,
          borderColor: ["rgba(75, 192, 192, 0.6)"],
          pointBorderWidth: 0,
        },
        {
          label: "Prescription",
          data: presRPM,
          backgroundColor: ["rgba(255, 206, 86, 0.6)"],
          borderWidth: 4,
          borderColor: ["rgba(255, 206, 86, 0.6)"],
        },
      ],
    });

    setNoninChartData({
      labels: chartLable,
      datasets: [
        {
          label: "Heart Rate",
          data: ArmbikeSession.data.heartRate,
          backgroundColor: ["rgba(213, 14, 65, 0.6)"],
          borderWidth: 4,
          borderColor: ["rgba(213, 14, 65, 0.6)"],
        },
        {
          label: "spO2",
          data: ArmbikeSession.data.spO2,
          backgroundColor: ["rgba(75, 192, 192, 0.6)"],
          borderWidth: 4,
          borderColor: ["rgba(75, 192, 192, 0.6)"],
        },
      ],
    });
  };

  useEffect(() => {
    SetLable();
    getLastestArmBikeData();
    getAllDates();
  }, []);

  const getDateValue = async(e) =>{
    const chartLable = [];
    setSelectDate(e.target.value)
    console.log("Hello" + e.target.value )

    SetLable()

    var select_date_value = await axios.get(
      `${config.url.API_URL}/Arm_Bike_Exercise_Object/datetime_value/${id}/${e.target.value}`
    );

    var date = new Date(select_date_value.data.date);
    date = date.toLocaleDateString(undefined, {day:'2-digit'}) + '-' + date.toLocaleDateString(undefined, {month:'short'}) + '-' + date.toLocaleDateString(undefined, {year:'numeric'} )  + '  ' + date.getHours(undefined, {hour:'numeric'}) + ':' + date.getMinutes(undefined, {hour:'numeric'})  
   
    // console.log(select_date_value.data.bikeSpeed);
    setCurrentDisplayDate(date)

    for (let i = 0; i < select_date_value.data.bikeSpeed.length; i++) {
      chartLable.push(i);
    }

    setChartData({
      labels: time,
      datasets: [
        {
          label: "Speed",
          data: select_date_value.data.bikeSpeed,
          backgroundColor: ["rgba(75, 192, 192, 0.6)"],
          borderWidth: 3,
          borderColor: ["rgba(75, 192, 192, 0.6)"],
          pointBorderWidth: 0,
        },
        {
          label: "Prescription",
          data: presRPM,
          backgroundColor: ["rgba(255, 206, 86, 0.6)"],
          borderWidth: 4,
          borderColor: ["rgba(255, 206, 86, 0.6)"],
        },
      ],
    });

    setNoninChartData({
      labels: chartLable,
      datasets: [
        {
          label: "Heart Rate",
          data: select_date_value.data.heartRate,
          backgroundColor: ["rgba(213, 14, 65, 0.6)"],
          borderWidth: 4,
          borderColor: ["rgba(213, 14, 65, 0.6)"],
        },
        {
          label: "spO2",
          data: select_date_value.data.spO2,
          backgroundColor: ["rgba(75, 192, 192, 0.6)"],
          borderWidth: 4,
          borderColor: ["rgba(75, 192, 192, 0.6)"],
        },
      ],
    });
  }



  return (
    <div>
      <div className={style.timeDropdownDiv}>
        <select value={selectDate} onChange={getDateValue} className="form-select" aria-label="Default select example">
          <option defaultValue>Open this select menu</option>
          {dateList.length > 0 ? dateList.map((date_value) =>{
            var date = new Date(date_value);
            date = date.toLocaleDateString(undefined, {day:'2-digit'}) + '-' + date.toLocaleDateString(undefined, {month:'short'}) + '-' + date.toLocaleDateString(undefined, {year:'numeric'} )  + '  ' + date.getHours(undefined, {hour:'numeric'}) + ':' + date.getMinutes(undefined, {hour:'numeric'}) + ':' + date.getMilliseconds(undefined, {hour:'numeric'}) 
            return <option key={date_value} value={date_value}>{date}</option>
          }):null}
        </select>
        <div style={{marginTop:"10px"}}><h4>Current Dispaly DateTime: {currentDisplayDate}</h4></div>
      </div>
      <div className={style.chartContainer}>
        <div className={style.chartsize}>
          <Line
            data={chartData}
            options={{
              animation: { duration: 0 },
              scales: {
                y: {
                  beginAtZero: true,
                  title: {
                    display: true,
                    text: 'speed'
                  },
                  min: 20, 
                  max: 100
                },
                x: {
                  title: {
                    display: true,
                    text: 'Second'
                  }
                }
              },
              // scales: { y: { min: 20, max: 100 } },
              elements: {
                point: {
                  radius: 0,
                },
              },
            }}
          />
        </div>
        <div className={style.chartsize}>
          <Line
            data={NoninchartData}
            options={{
              animation: { duration: 0 },
              scales: {
                y: {
                  beginAtZero: true,
                  title: {
                    display: true,
                    text: 'bpm / SPO2'
                  },
                  min: 60, 
                  max: 130
                },
                x: {
                  title: {
                    display: true,
                    text: 'Second'
                  }
                }
              },
              // scales: { y: { min: 60, max: 140 } },
              elements: {
                  point:{
                      radius: 0
                  }
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ArmBikeDataDisplay;
