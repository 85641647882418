import React, { useState, useEffect } from "react";
import axios from "axios";
import style from "./CommunityDashboard.module.css";
import { config } from "../setting";
import Dialog from "../layout/Dialog";

import GroupPost from "./GroupPost";
import ChatBox from "./ChatBox";

import { useParams } from "react-router-dom";

const CommunityDashboard = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [patientList, setPatientList] = useState([]);
  const [displayList, setDisplayList] = useState([]);
  const [selectPatient, setSelectPatient] = useState([]);
  const [MemberLis, setMemberList] = useState([]);

  const [communityGroup, GetCommunityGroup] = useState([]);
  const [groupShow, setGroupShow] = useState(false);
  const [showPatientList, setShowPatientList] = useState(false);

  const [newPostBtn, setNewPostBtn] = useState(false);
  const [rightSideShow, setRightSiteShow] = useState("POSTTOALL");
  const [displayObjectID, setDisplayObjectID] = useState(0);
  const [displayGroup, setDisplayGroup] = useState({});

  const [userInfo, setUserInfo] = useState({});

  const { id, user } = useParams();

  const GetPatientList = async () => {
    await axios.get(`${config.url.API_URL}/hat_petient`).then(async (res) => {
      // console.log(res.data)
      setPatientList(res.data);
    });
  };

  const GetCommunityGroupList = async () => {
    if(user== 'admin'){
        await axios.get(`${config.url.API_URL}/community`).then(async (res) => {
            console.log(res.data);
            GetCommunityGroup(res.data);
          });
    }else{
        await axios.get(`${config.url.API_URL}/community/user_group/${id}`).then(async (res) => {
            console.log(res.data);
            GetCommunityGroup(res.data);
          });
    }
   
  };

  const SearchPatient = () => {
    const list = patientList.filter((patient) => {
      return (
        searchInput &&
        (patient.LAST_NAME.toLowerCase().includes(searchInput) ||
          patient.FIRST_NAME.toLowerCase().includes(searchInput))
      );
    });
    setDisplayList(list);
  };

  const setSearchValue = (patient) => {
    setSearchInput(
      `${patient.Patient_ID} : ${patient.LAST_NAME}, ${patient.FIRST_NAME}`
    );
    setSelectPatient(patient);
  };

  const AddMember = () => {
    if (
      selectPatient !== "" &&
      MemberLis &&
      !MemberLis.includes(selectPatient) && searchInput !== ""
    ) {
      setMemberList([...MemberLis, selectPatient]);
      setSelectPatient("");
      setSearchInput("")
    }
  };

  const CreateCommunityGroup = async () => {
    await axios
      .post(`${config.url.API_URL}/community/add_group`, {
        groupName: groupName,
        member_list: MemberLis,
      })
      .then((response) => {
        console.log(response.data);
        var addNew = {
          id:response.data.createGroup.id,
          groupName:response.data.createGroup.groupName
        }
        GetCommunityGroup([...communityGroup, addNew]);
        setIsOpen(false)
        setGroupName("")
        setMemberList([])
      })
      .catch((err) => {
        // setErrorMessage(err.response.data.error)
        console.log(err.response.data.error);
      });
  };

  const SetRightSidePage = () => {
    if (rightSideShow == "POSTTOALL") {
      return <GroupPost patientList={patientList} groupID={1} userInfo={userInfo} userType={user}/>;
    } else if (rightSideShow == "GroupPost") {
      return <GroupPost patientList={patientList} groupID={displayObjectID} userInfo={userInfo} userType={user} />;
    } else if (rightSideShow == "ChatBox") {
      return <ChatBox Patient_ID={displayObjectID} />;
    }
  };

  const SetGroupContent = (group) => {
    setDisplayObjectID(group.id);
    setRightSiteShow("GroupPost");
    // setDisplayGroup(group)
  };

  const PatientChartBox = (id) => {
    console.log(id);
    setDisplayObjectID(id);
    setRightSiteShow("ChatBox");
  };

  const getUserInfo = async () => {
    if (user == "admin") {
      await axios
        .get(`${config.url.API_URL}/hat_staff/${id}`)
        .then(async (res) => {
        //   console.log(res.data);
          setUserInfo(res.data);
        });
    } else {
      await axios
        .get(`${config.url.API_URL}/hat_petient/${id}`)
        .then(async (res) => {
        //   console.log(res.data);
          setUserInfo(res.data);
        });
    }
  };

  useEffect(() => {
    GetPatientList();
    GetCommunityGroupList();
    // console.log(user)
    getUserInfo();
  }, []);

  useEffect(() => {
    SearchPatient();
  }, [searchInput]);

  return (
    <div className={style.dashboard}>
      <div>
        <div>
          {user == "admin" ? (
            <div className={style.GroupSpace}>
              <button
                className={style.CreateCommunityBtn}
                onClick={(e) => setIsOpen(true)}
              >
                {" "}
                Create Community{" "}
              </button>
              <Dialog isOpen={isOpen} onClose={(e) => setIsOpen(false)}>
                <div>
                  <div class="input-group mb-3">
                    <span
                      class="input-group-text"
                      id="inputGroup-sizing-default"
                    >
                      Group Name
                    </span>
                    <input
                      value={groupName}
                      onChange={(e) => setGroupName(e.target.value)}
                      type="text"
                      class="form-control"
                      aria-label="Sizing example input"
                      aria-describedby="inputGroup-sizing-default"
                    />
                  </div>
                  {MemberLis.length !== 0 ? (
                    <div>
                      {MemberLis.map((patient) => {
                        return (
                          <p>
                            {patient.LAST_NAME}, {patient.FIRST_NAME}
                          </p>
                        );
                      })}
                    </div>
                  ) : null}
                  <div class="input-group mb-3">
                    <span
                      class="input-group-text"
                      id="inputGroup-sizing-default"
                    >
                      Member
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      aria-label="Sizing example input"
                      placeholder="Search by name"
                      aria-describedby="inputGroup-sizing-default"
                      value={searchInput}
                      onChange={(e) => {
                        setSearchInput(e.target.value);
                      }}
                    />
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      id="button-addon2"
                      onClick={() => AddMember()}
                    >
                      +
                    </button>
                  </div>
                  <div className={style.resultList}>
                    {displayList.length !== 0
                      ? displayList.map((patient) => {
                          return (
                            <div
                              key={patient.Patient_ID}
                              onClick={() => setSearchValue(patient)}
                            >
                              {patient.LAST_NAME}, {patient.FIRST_NAME}
                            </div>
                          );
                        })
                      : null}
                  </div>
                  <button className={style.PostSubmitBtn} onClick={() => CreateCommunityGroup()}>Create</button>
                  <button className={style.PostSubmitBtn} onClick={(e) => setIsOpen(false)}>Close</button>
                </div>
              </Dialog>
            </div>
          ) : null}
        </div>
        <div className={style.GroupSpace}>
          <button
            className={style.GroupflodBtn}
            onClick={() => {
              setGroupShow(!groupShow);
            }}
          >
            Community group
          </button>
          {groupShow == true ? (
            <div>
              {communityGroup.length > 0 ? (
                <div>
                  {communityGroup.map((group) => {
                    return (
                      <div onClick={() => SetGroupContent(group)}>
                        <button className={style.groupBtn}>
                          {group.groupName}
                        </button>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <p>No other group, please great group</p>
              )}
            </div>
          ) : null}
        </div>
        {user == "admin" ? (
          <div className={style.GroupSpace}>
            <button
              className={style.GroupflodBtn}
              onClick={() => {
                setShowPatientList(!showPatientList);
              }}
            >
              Patient list
            </button>
            {showPatientList == true ? (
              <div>
                {patientList.length > 0 ? (
                  <div>
                    {patientList.map((patient) => {
                      return (
                        <div
                          onClick={() => PatientChartBox(patient.Patient_ID)}
                        >
                          <button className={style.groupBtn}>
                            {patient.FIRST_NAME} {patient.LAST_NAME}{" "}
                          </button>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <p>No patient</p>
                )}
              </div>
            ) : null}
          </div>
        ) : null}
      </div>

      <div>{SetRightSidePage()}</div>
    </div>
  );
};

export default CommunityDashboard;
